<template>
  <svg viewBox="0 0 512 512" :width="size" :height="size" class="stop-icon">
    <g>
      <!-- TODO check colors and move to icons.css -->
      <path
        d="M456.099,98.5c-5.7-8.101-12.599-15.601-19.799-22.8s-14.7-14.101-22.8-19.801  C368.8,20.2,313.9,0,256,0C187.899,0,123.999,27.4,75.7,75.7S0,187.899,0,256c0,58.2,20.2,113.099,55.901,157.8  c6,7.798,12.599,15.298,19.799,22.5c7.2,7.2,14.7,13.799,22.5,19.799C142.899,491.8,197.8,512,256,512  c68.101,0,132.001-27.4,180.3-75.7C484.6,387.999,512,324.099,512,256C512,198.1,491.8,143.2,456.099,98.5z M61,256  c0-52.2,20.4-101.1,57.001-138.001C154.9,81.4,203.8,61,256,61c42.001,0,81.899,13.2,114.901,37.5L98.8,370.899  C74.2,337.899,61,297.999,61,256z M393.999,393.999C357.1,430.6,308.2,451,256,451c-42.001,0-81.899-13.2-114.901-37.8L413.5,141.1  C437.8,174.099,451,213.999,451,256C451,308.2,430.6,357.1,393.999,393.999z"
        data-original="#FD003A"
      />
      <path
        d="M98.2,456.099C142.899,491.8,197.8,512,256,512c68.101,0,132.001-27.4,180.3-75.7  C484.6,387.999,512,324.099,512,256c0-57.9-20.2-112.8-55.901-157.5c-5.7-8.101-12.599-15.601-19.799-22.8L75.7,436.3  C82.899,443.5,90.399,450.099,98.2,456.099z M413.5,141.099c24.3,33,37.5,72.9,37.5,114.901c0,52.2-20.4,101.1-57.001,137.999  C357.1,430.6,308.2,451,256,451c-42.001,0-81.899-13.2-114.901-37.8L413.5,141.099z"
        data-original="#E50027"
        data-old_color="#f53847"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "StopIcon",
  props: {
    size: {
      type: [Number, String],
      default: 20,
    },
  },
};
</script>
