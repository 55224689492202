import { createStore } from "vuex";
import user from "./modules/user";
import notifications from "./modules/notifications";
import auth from "./modules/auth";
import customer from "./modules/customer";
import contractor from "./modules/contractor";
import admin from "./modules/admin";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    notifications,
    auth,
    customer,
    contractor,
    admin,
  },
});
