<template>
  <div v-if="notes.length">
    <p class="mb-20 mt-50 f-13-black f-bold">{{ $t("notes") }}</p>
    <div class="unassigned-jobs-table">
      <div class="unassigned-jobs-table__header row-start f-12-white f-bold">
        <div class="lg-2 p-4 s-align-5">
          {{ $t("jobs.table.date_added") }}
        </div>
        <div class="lg-10 p-4 s-align-5">
          {{ $t("jobs.table.log") }}
        </div>
      </div>
      <div
        class="unassigned-jobs-table__body row-start f-12-black"
        v-for="note in notes"
        :key="note.id"
      >
        <div class="lg-2 p-4 s-align-5 bb-black bl-black br-black">
          {{ dateFormat(note.created_at) }}
        </div>
        <div class="lg-10 p-4 bb-black br-black break-all">
          {{ getNoteSignature(note) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/helpers/commonHelpers";
import { NOTE_TYPES } from "@/constants/commonConstants";

export default {
  name: "NotesTable",
  props: {
    notes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    // Methods imported from commonHelpers.js
    dateFormat,
    getNoteSignature(note) {
      let noteSignature = "";
      let firstName = "";
      let lastName = "";
      let role = "";

      switch (note.type) {
        case NOTE_TYPES.REJECT:
          {
            noteSignature = "Rejection Reason";
          }
          break;
        case NOTE_TYPES.NOTE:
          {
            noteSignature = "Admin Note";
          }
          break;
        case NOTE_TYPES.UNEDITABLE:
          {
            noteSignature = "Uneditable Note";
          }
          break;
      }

      firstName = note?.owner?.first_name || "";
      lastName = note?.owner?.last_name || "";
      role = note?.owner?.role || "";

      noteSignature += ` [${firstName} ${lastName}, ${
        role.charAt(0).toUpperCase() + role.slice(1)
      }]: ${note.text}`;

      return noteSignature;
    },
  },
};
</script>
