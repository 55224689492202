/* eslint-disable no-empty-pattern */
import {
  GET_ORDER_HADBOOKS,
  SET_ORDER_HADBOOKS,
  CREATE_ORDER,
  PAY_ORDER,
  GET_CUSTOMER_ORDERS,
  APPROVE_ORDER,
  REJECT_ORDER,
  DOWNLOAD_INVOICE,
  SET_ORDER_DATA,
  CANCEL_ORDER,
} from "../actions/customer";
import api from "@/api/api.js";
import { ORDER_DOC_TYPES, ORDER_RATES } from "@/constants/commonConstants";

export default {
  namespaced: true,
  state: {
    documentTypes: [],
    rates: [],
    orderData: {},
  },
  getters: {
    getDocumentTypes: (state) => state.documentTypes,
    getRates: (state) => state.rates,
    getOrderData: (state) => state.orderData,
  },
  mutations: {
    [SET_ORDER_HADBOOKS](state, payload) {
      let documents = [];
      let rates = [];

      if (payload.document_types) {
        ORDER_DOC_TYPES.forEach((type) => {
          payload.document_types.map((el) => {
            if (type.NAME === el.name) {
              documents.push({
                ...el,
                title: type.TRANSLATION_KEY,
              });
            }
          });
        });
      }
      state.documentTypes = documents;

      if (payload.rates) {
        ORDER_RATES.forEach((rate) => {
          payload.rates.map((el) => {
            if (rate.NAME === el.name) {
              rates.push({
                ...el,
                title: rate.TRANSLATION_KEY,
              });
            }
          });
        });
      }
      state.rates = rates;
    },
    [SET_ORDER_DATA](state, payload) {
      state.orderData = payload;
    },
  },
  actions: {
    [GET_ORDER_HADBOOKS]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .get(`/handbook`, payload)
          .then((response) => response.data.data)
          .then((data) => {
            commit("SET_ORDER_HADBOOKS", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CREATE_ORDER]({}, payload) {
      return new Promise((resolve, reject) => {
        api
          .post(`/orders`, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [PAY_ORDER]({}, { id, payload }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/orders/${id}/pay`, payload)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CANCEL_ORDER]({}, id) {
      return new Promise((resolve, reject) => {
        api
          .post(`/orders/${id}/cancel-unpaid`)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_CUSTOMER_ORDERS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/client-orders`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [APPROVE_ORDER]({}, orderId) {
      return new Promise((resolve, reject) => {
        api
          .post(`/orders/${orderId}/approve`)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [REJECT_ORDER]({}, { orderId, params }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/orders/${orderId}/reject`, params)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DOWNLOAD_INVOICE]({}, jobId) {
      return new Promise((resolve, reject) => {
        api
          .post(
            `/orders/${jobId}/download-invoice`,
            {},
            { responseType: "blob" }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
