<template>
  <div class="unassigned-jobs-table">
    <div class="unassigned-jobs-table__header row-start f-12-white f-bold">
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.date_added") }}</div>
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.date_accepted") }}</div>
      <div class="lg-3 p-4 s-align-5">{{ $t("jobs.table.submit_time") }}</div>
      <div class="lg-1 p-4 s-align-5">{{ $t("jobs.table.payout") }}</div>
      <div class="lg-1 p-4 s-align-5">{{ $t("jobs.table.word_count") }}</div>
      <div class="lg-3 p-4 s-align-5">
        {{ $t("jobs.table.academic_field") }}
      </div>
    </div>
    <div class="unassigned-jobs-table__body row-start f-12-black">
      <div class="lg-2 p-4 s-align-5 bb-black bl-black br-black">
        {{ dateFormat(jobInProgress.created_at) }}
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{
          dateFormat(jobInProgress.accepted_at, $t("jobs.table.not_accepted"))
        }}
      </div>
      <div class="lg-3 p-4 s-align-5 bb-black br-black">
        <template v-if="jobInProgress?.paid_at">
          {{ contractorTimeLeft(jobInProgress) }}
        </template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div class="lg-1 p-4 s-align-5 bb-black br-black">
        <template v-if="jobInProgress.payout">
          {{
            $n(
              jobInProgress.payout,
              "currency",
              jobInProgress.currency === "aud" ? "en" : "ja"
            )
          }}
        </template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div class="lg-1 p-4 s-align-5 bb-black br-black">
        {{ jobInProgress.word_count }}
      </div>
      <div class="lg-3 p-4 s-align-5 bb-black br-black">
        {{ jobInProgress.academic_field }}
      </div>
    </div>
    <div class="column-start bb-black bl-black br-black p-4">
      <div class="row-start">
        <div
          :class="[
            'f-13-black f-bold row-center',
            $i18n.locale === 'en' ? 'lg-7' : 'lg-5',
          ]"
        >
          <template v-if="jobInProgress?.document_original?.original_name">
            {{ jobInProgress.document_original.original_name }}
          </template>
          <template v-else>{{ $t("order.table.doc_empty") }}</template>
        </div>
        <div :class="['row-between', $i18n.locale === 'en' ? 'lg-5' : 'lg-7']">
          <button
            class="secondary-button"
            :disabled="isUneditableOrder"
            @click="
              handleDownloadFile(
                jobInProgress.document_original.id,
                jobInProgress.document_original.original_name
              )
            "
          >
            {{ $t("btn.download_doc") }}
          </button>
          <button
            class="secondary-button"
            :disabled="isUneditableOrder"
            @click="openModal"
          >
            {{ $t("btn.upload_doc") }}
          </button>
          <a
            class="secondary-button"
            target="_blanck"
            :href="`mailto:${CONTRACTOR_SUPPORT_EMAIL}`"
          >
            {{ $t("btn.contact_manager") }}
          </a>
        </div>
      </div>
      <div class="row-end">
        <div class="lg-10">
          <file-uploader
            :class="['mv-4', { 'invalid-file-uploader': documentNotUploaded }]"
            ref="fileUploader"
            @uploadedFiles="handleUploadedFile"
          />
        </div>
      </div>
      <Form
        class="row-start mt-5"
        :validation-schema="validationSchema"
        :initial-values="uneditableReson"
        @invalid-submit="onInvalidSubmit"
        @submit="handleForm"
        v-slot="{ errors }"
      >
        <div class="s-align-2 lg-2">
          <button class="secondary-button" :disabled="isUneditableOrder">
            {{ $t("btn.report_un-editable") }}
          </button>
        </div>
        <text-input
          name="reason"
          id="reason"
          :errors="errors"
          :inputContainerClass="'lg-10'"
          fieldType="text"
          :placeholder="$t('order.reson_placeholder')"
          :renderAs="'textarea'"
          :disabled="isUneditableOrder"
        />
      </Form>
    </div>
  </div>
  <upload-file-modal
    v-if="confirmUploadDocument"
    @closeModal="closeModal"
    @uploadFile="uploadFile"
  />
</template>

<script>
import UploadFileModal from "../UploadFileModal.vue";
import { Form } from "vee-validate";
import * as yup from "yup";
// eslint-disable-next-line no-unused-vars
import setLocale from "@/helpers/validatorLocalization";
import {
  onInvalidSubmit,
  downloadFile,
  dateFormat,
  contractorTimeLeft,
} from "@/helpers/commonHelpers";
import {
  ORDER_STATUSES,
  CONTRACTOR_SUPPORT_EMAIL,
} from "@/constants/commonConstants";

export default {
  name: "JobInProgressTable",
  components: {
    UploadFileModal,
    Form,
  },
  props: {
    jobInProgress: {
      type: Object,
      required: true,
    },
  },
  emits: ["updateMyJobs"],
  data() {
    // Form validation schema
    const validationSchema = yup.object().shape({
      reason: yup.string().required(),
    });

    return {
      validationSchema,
      uneditableReson: {},
      documentNotUploaded: false,
      uploadedDocument: null,
      CONTRACTOR_SUPPORT_EMAIL,
      componentInterval: null,
      confirmUploadDocument: false,
    };
  },
  computed: {
    isUneditableOrder() {
      return this.jobInProgress.status === ORDER_STATUSES[3].STATUS;
    },
  },
  methods: {
    // Methods imported from commonHelpers.js
    downloadFile,
    onInvalidSubmit,
    dateFormat,
    contractorTimeLeft,
    handleForm(values) {
      if (this.isUneditableOrder) {
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("notifications.uneditable_order")
        );
        return;
      }

      let params = {
        jobId: this.jobInProgress.id,
        payload: values,
      };

      this.$store
        .dispatch("contractor/REPORT_UNEDITABLE_DOCUMENT", params)
        .then(() => {
          this.$emit("updateMyJobs");
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        });
    },
    handleUploadedFile(file) {
      this.uploadedDocument = file[0];
      this.documentNotUploaded = false;
    },
    openModal() {
      if (this.isUneditableOrder) {
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("notifications.uneditable_job_docs")
        );
        return;
      }

      if (!this.uploadedDocument) {
        this.documentNotUploaded = true;
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("validation_errors.file_required")
        );
        return;
      }

      this.confirmUploadDocument = true;
    },
    uploadFile() {
      let formData = new FormData();
      formData.append("file", this.uploadedDocument);
      let params = {
        jobId: this.jobInProgress.id,
        payload: formData,
      };
      this.$store
        .dispatch("contractor/UPLOAD_EDITED_DOCUMENT", params)
        .then(() => {
          this.confirmUploadDocument = false;
          this.$emit("updateMyJobs");
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        });
    },
    handleDownloadFile(fileId, fileName) {
      if (this.isUneditableOrder) {
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("notifications.uneditable_job_docs")
        );
        return;
      }

      this.downloadFile(fileId, fileName);
    },
    closeModal() {
      this.confirmUploadDocument = false;
      this.$refs.fileUploader.clearSelectedFile();
      this.uploadedDocument = null;
    },
  },
  created() {
    this.uneditableReson["reason"] = this.jobInProgress?.rejection_reason || "";
  },
  mounted() {
    // This interval for rerender component once per minute
    // to start the timers
    this.componentInterval = setInterval(() => {
      this.$forceUpdate();
    }, 60000);
  },
  beforeUnmount() {
    clearInterval(this.componentInterval);
  },
};
</script>
