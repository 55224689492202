<template>
  <div class="row-start">
    <ul class="pagination w-80">
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickFirstPage"
          :disabled="isInFirstPage"
          aria-label="Go to first page"
        >
          {{ $t("table_pagination.first") }}
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
          aria-label="Go to previous page"
        >
          {{ $t("table_pagination.prev") }}
        </button>
      </li>

      <li
        v-for="(page, index) in pages"
        :key="index"
        :class="'pagination-item'"
      >
        <button
          type="button"
          @click="onClickPage(page.name)"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
          :aria-label="`Go to page number ${page.name}`"
        >
          {{ page.name }}
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickNextPage"
          :disabled="isInLastPage"
          aria-label="Go to next page"
        >
          {{ $t("table_pagination.next") }}
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickLastPage"
          :disabled="isInLastPage"
          aria-label="Go to last page"
        >
          {{ $t("table_pagination.last") }}
        </button>
      </li>
    </ul>
    <div class="row-end row-baseline w-20">
      <p class="mr-5 f-14-black f-bold">
        {{ $t("table_pagination.per_page") }}:
      </p>
      <vue-select
        class="vue-select--pagination"
        :options="perPageOptions"
        v-model="selectedPerPage"
        close-on-select
        openDirection="top"
        @selected="selectPerPage"
      >
      </vue-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "TablePagination",
  data() {
    return {
      selectedPerPage: this.perPage,
    };
  },
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      default: () => {
        return [5, 10, 20, 50, 100];
      },
    },
  },
  emits: ["pagechanged", "perPageChanged"],
  computed: {
    maxVisibleButtons() {
      return this.totalPages >= 3 ? 3 : this.totalPages;
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
    selectPerPage(value) {
      this.$emit("perPageChanged", value);
    },
  },
};
</script>
