<template>
  <div class="unassigned-jobs-table mb-60" v-if="showCustomerInfo">
    <div class="unassigned-jobs-table__header row-start f-12-white f-bold p-4">
      <div class="lg-3 column-start">
        <span>{{ $t("order.table.status") }}</span>
        <span>{{ $t(showCorrectStatus(jobDetails)) }}</span>
      </div>
      <div
        class="column-start lg-3 pointer"
        @click="showCopyAlert(jobDetails)"
        v-clipboard="
          `${jobDetails.payment_intent_id ? jobDetails.payment_intent_id : ''}`
        "
      >
        <span>{{ $t("order.table.order_reference") }}</span>
        <span>
          <template v-if="jobDetails.payment_intent_id">
            {{ showOrderId(jobDetails) }}
          </template>
          <template v-else>{{ $t("no_info") }}</template>
        </span>
      </div>
      <div class="column-start lg-6">
        <span>{{ $t("order.table.payment_date") }}</span>
        <span>
          <template v-if="jobDetails.paid_at">
            {{ dateFormatWithTZ(jobDetails.paid_at) }}
          </template>
          <template v-else>{{ $t("no_info") }}</template>
        </span>
      </div>
    </div>
    <div class="br-black bb-black bl-black p-4 pb-8 row-start f-12-black">
      <div class="lg-3 column-start">
        <span class="mb-8">{{ $t("jobs.table.customer_name") }}:</span>
        <span>{{ fullName(jobDetails.client) }}</span>
      </div>
      <div class="lg-3 column-start">
        <span class="mb-8">{{ $t("input_fields.institution") }}:</span>
        <span v-if="jobDetails?.client?.institution">
          {{ jobDetails.client.institution }}
        </span>
        <span v-else>{{ $t("no_info") }}</span>
      </div>
    </div>
  </div>
  <div class="unassigned-jobs-table">
    <div class="unassigned-jobs-table__header row-start f-12-white f-bold">
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.date_added") }}
      </div>
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.date_accepted") }}
      </div>
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.contractor_time_left") }}
      </div>
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.payout") }}</div>
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.word_count") }}
      </div>
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.academic_field") }}
      </div>
    </div>
    <div class="row-start f-12-black">
      <div class="lg-2 p-4 s-align-5 bb-black bl-black br-black">
        {{ dateFormat(jobDetails.created_at) }}
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{ dateFormat(jobDetails.accepted_at, $t("jobs.table.not_accepted")) }}
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        <template v-if="jobDetails?.paid_at">
          {{ contractorTimeLeft(jobDetails) }}
        </template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div
        class="lg-2 p-4 s-align-5 bb-black br-black"
        contenteditable="true"
        @input="handleChangeField($event, EDITING_FIELD.PAYOUT)"
      >
        <template v-if="jobDetails.payout">
          {{
            $n(
              jobDetails.payout,
              "currency",
              jobDetails.currency === "aud" ? "en" : "ja"
            )
          }}
        </template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div
        class="lg-2 p-4 s-align-5 bb-black br-black"
        contenteditable="true"
        @input="handleChangeField($event, EDITING_FIELD.WORD_COUNT)"
      >
        {{ jobDetails.word_count }}
      </div>
      <div
        class="lg-2 p-4 s-align-5 bb-black br-black"
        contenteditable="true"
        @input="handleChangeField($event, EDITING_FIELD.ACADEMIC_FIELD)"
      >
        {{ jobDetails.academic_field }}
      </div>
    </div>
  </div>
  <div class="unassigned-jobs-table">
    <div class="unassigned-jobs-table__header row-start f-12-white f-bold">
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.assigned_to") }}
      </div>
      <div class="lg-3 p-4 s-align-5">
        {{ $t("jobs.table.status") }}
      </div>
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.time_finalize") }}
      </div>
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.paid_amount") }}</div>
      <div class="lg-3 p-4 s-align-5">
        {{ $t("jobs.table.service") }}
      </div>
    </div>
    <div class="row-start f-12-black">
      <div class="lg-2 s-align-5 bb-black bl-black br-black">
        <vue-select
          class="w-100 vue-select--no-border"
          :options="contractorsList"
          label-by="id"
          close-on-select
          v-model="selectedContractors"
          @selected="editJob($event, EDITING_FIELD.CONTRACTOR)"
        >
          <template #label="{ selected }">
            <template v-if="selected">
              <div>
                {{ fullName(selected) }}
              </div>
            </template>
          </template>
          <template #dropdown-item="{ option }">
            <div>
              {{ fullName(option) }}
            </div>
          </template>
        </vue-select>
      </div>
      <div class="lg-3 s-align-5 bb-black br-black">
        <vue-select
          class="w-100 vue-select--no-border"
          :options="ORDER_STATUSES"
          label-by="STATUS"
          close-on-select
          v-model="selectedStatus"
          @selected="editJob($event, EDITING_FIELD.STATUS)"
        >
          <template #label="{ selected }">
            <template v-if="selected">
              <div>
                {{ $t(`order_status.${selected.TRANSLATE_KEY}`) }}
              </div>
            </template>
          </template>
          <template #dropdown-item="{ option }">
            <div>
              {{ $t(`order_status.${option.TRANSLATE_KEY}`) }}
            </div>
          </template>
        </vue-select>
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        <template v-if="jobDetails?.paid_at">{{
          timeToFinalize(jobDetails)
        }}</template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{
          $n(
            jobDetails.total,
            "currency",
            jobDetails.currency === "aud" ? "en" : "ja"
          )
        }}
      </div>
      <div class="lg-3 s-align-5 bb-black br-black">
        <vue-select
          :class="[
            'w-100 vue-select--no-border',
            selectedTimeError ? 'invalid' : '',
          ]"
          :options="orderRates"
          label-by="id"
          close-on-select
          v-model="selectedRate"
          @selected="editJob($event, EDITING_FIELD.RATE)"
        >
          <template #label="{ selected }">
            <template v-if="selected">
              <div>
                {{ $t(`turnaround_time.${selected.title}`) }}
              </div>
            </template>
            <template v-else>{{ $t("input_fields.turnaround_time") }}</template>
          </template>
          <template #dropdown-item="{ option }">
            <div>
              {{ $t(`turnaround_time.${option.title}`) }}
            </div>
          </template>
        </vue-select>
      </div>
    </div>
    <div class="column-start bb-black bl-black br-black f-12-black p-4">
      <div class="row-start">
        <div class="lg-2 f-12-black f-bold">Order number</div>
      </div>
      <div class="row-start">
        <div class="lg-4 s-align-7">
          <span class="mr-20">{{ $t("original_file") }}:</span>
          <span
            class="f-bold"
            v-if="jobDetails?.document_original?.original_name"
          >
            {{ jobDetails.document_original.original_name }}
          </span>
          <span v-else class="f-bold">{{ $t("no_info") }}</span>
        </div>
        <div class="lg-8 column-start">
          <div class="lg-12">
            <file-uploader
              :class="[
                'mv-4',
                { 'invalid-file-uploader': documentNotUploaded },
              ]"
              ref="fileUploader"
              @uploadedFiles="handleUploadedFile"
            />
          </div>
          <div class="row-start">
            <button
              class="secondary-button mr-5"
              @click="
                handleDownloadFile(
                  jobDetails?.document_original?.id,
                  jobDetails?.document_original?.original_name
                )
              "
            >
              {{ $t("btn.download_file") }}
            </button>
            <button
              class="secondary-button mr-5"
              @click="openModal(DOC_TYPES.ORIGINAL)"
            >
              {{ $t("btn.upload_file") }}
            </button>
            <button
              class="secondary-button"
              @click="deleteFile(jobDetails?.document_original?.id)"
            >
              {{ $t("btn.delete_file") }}
            </button>
          </div>
        </div>
      </div>
      <div class="row-start row-baseline mt-5">
        <div class="lg-4">
          <span class="mr-25">{{ $t("edited_file") }}:</span>
          <span
            class="f-bold"
            v-if="jobDetails?.document_edited?.original_name"
          >
            {{ jobDetails.document_edited.original_name }}
          </span>
          <span v-else class="f-bold">
            {{ $t("order.table.doc_empty") }}
          </span>
        </div>
        <div class="lg-8 column-start">
          <div class="row-start">
            <button
              class="secondary-button mr-5"
              @click="
                handleDownloadFile(
                  jobDetails?.document_edited?.id,
                  jobDetails?.document_edited?.original_name
                )
              "
            >
              {{ $t("btn.download_file") }}
            </button>
            <button
              class="secondary-button mr-5"
              @click="openModal(DOC_TYPES.EDITED)"
            >
              {{ $t("btn.upload_finished_file") }}
            </button>
            <button
              class="secondary-button"
              @click="deleteFile(jobDetails?.document_edited?.id)"
            >
              {{ $t("btn.delete_file") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="column-start bb-black bl-black br-black f-12-black p-4"
      v-if="showNoteForm"
    >
      <div class="row-start">
        <div class="lg-2 f-12-black f-bold">{{ $t("add_note") }}</div>
      </div>
      <Form
        class="row-start mt-5"
        :validation-schema="validationSchema"
        @invalid-submit="onInvalidSubmit"
        @submit="handleForm"
        v-slot="{ errors }"
      >
        <div class="s-align-4 lg-2">
          <button class="secondary-button">
            {{ $t("btn.add_note") }}
          </button>
        </div>
        <text-input
          name="text"
          id="text"
          :errors="errors"
          :inputContainerClass="'lg-10'"
          fieldType="text"
          :placeholder="$t('order.note_placeholder')"
          :renderAs="'textarea'"
        />
      </Form>
    </div>
    <div
      class="column-start bb-black bl-black br-black f-12-black p-4"
      v-if="showReportUneditableForm"
    >
      <Form
        class="row-start mt-5"
        :validation-schema="uneditableValidationSchema"
        @invalid-submit="onInvalidSubmit"
        @submit="reportUneditable"
        v-slot="{ errors }"
      >
        <div class="s-align-2 lg-2">
          <button class="secondary-button">
            {{ $t("btn.report_un-editable") }}
          </button>
        </div>
        <text-input
          name="reason"
          id="reason"
          :errors="errors"
          :inputContainerClass="'lg-10'"
          fieldType="text"
          :placeholder="$t('order.reson_placeholder')"
          :renderAs="'textarea'"
        />
      </Form>
    </div>
  </div>
  <upload-file-modal
    v-if="confirmUploadDocument"
    @closeModal="closeModal"
    @uploadFile="uploadFile"
  />
</template>

<script>
import { Form } from "vee-validate";
import UploadFileModal from "../UploadFileModal.vue";
import * as yup from "yup";
// eslint-disable-next-line no-unused-vars
import setLocale from "@/helpers/validatorLocalization";
import { ORDER_STATUSES, DOC_TYPES } from "@/constants/commonConstants";
import {
  dateFormat,
  fullName,
  showCorrectStatus,
  downloadFile,
  onInvalidSubmit,
  contractorTimeLeft,
  timeToFinalize,
  dateFormatWithTZ,
  showOrderId,
} from "@/helpers/commonHelpers";
import { mapGetters } from "vuex";

const EDITING_FIELD = {
  CONTRACTOR: "contractor",
  STATUS: "status",
  RATE: "rate",
  WORD_COUNT: "word_count",
  ACADEMIC_FIELD: "academic_field",
  PAYOUT: "payout",
};

export default {
  name: "JobDetailsTable",
  components: {
    Form,
    UploadFileModal,
  },
  props: {
    jobId: {
      type: [Number, String],
      required: true,
    },
    jobDetails: {
      type: Object,
      reqired: true,
    },
    showCustomerInfo: {
      type: Boolean,
      default: true,
    },
    showNoteForm: {
      type: Boolean,
      default: true,
    },
    showReportUneditableForm: {
      type: Boolean,
      default: false,
    },
    isAdminJobInProgress: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateJob"],
  data() {
    // Form validation schema
    const validationSchema = yup.object().shape({
      text: yup.string().required(),
    });
    // Form validation schema
    const uneditableValidationSchema = yup.object().shape({
      reason: yup.string().required(),
    });

    return {
      ORDER_STATUSES,
      DOC_TYPES,
      EDITING_FIELD,
      selectedRate: null,
      selectedTimeError: false,
      selectedStatus: null,
      selectedContractors: null,
      documentNotUploaded: false,
      uploadedDocument: null,
      validationSchema,
      uneditableValidationSchema,
      componentInterval: null,
      confirmUploadDocument: false,
      uploadedDocType: null,
      debounce: null,
    };
  },
  computed: {
    ...mapGetters({
      orderRates: "customer/getRates",
      contractorsList: "admin/getContractors",
    }),
  },
  watch: {
    jobDetails: {
      deep: true,
      handler() {
        this.setCurentStatusForOrder();

        if (!this.contractorsList.length) {
          this.getContractors();
        } else {
          this.setSelectedContractors();
        }
      },
    },
  },
  methods: {
    // Methods imported from commonHelpers.js
    dateFormat,
    fullName,
    showCorrectStatus,
    downloadFile,
    onInvalidSubmit,
    contractorTimeLeft,
    timeToFinalize,
    dateFormatWithTZ,
    showOrderId,
    getRates() {
      this.showLoader = true;
      let params = {
        needed: ["rates"],
      };

      this.$store
        .dispatch("customer/GET_ORDER_HADBOOKS", { params })
        .then(() => {
          this.setSelectedRates();
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        })
        .finally(() => (this.showLoader = false));
    },
    setSelectedRates() {
      this.selectedRate = this.orderRates.find(
        (el) => el.id === this.jobDetails?.rate_id
      );
    },
    getContractors() {
      let params = {
        needed: ["contractors"],
      };

      this.$store
        .dispatch("admin/GET_CONTRACTORS_LIST", { params })
        .then(() => {
          this.setSelectedContractors();
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        });
    },
    setSelectedContractors() {
      this.selectedContractors = this.contractorsList.find((el) => {
        return el.id == this.jobDetails?.contractor_id;
      });
    },
    setCurentStatusForOrder() {
      this.selectedStatus = this.ORDER_STATUSES.find(
        (el) => el.STATUS === this.jobDetails.status
      );
    },
    handleUploadedFile(file) {
      this.uploadedDocument = file[0];
      this.documentNotUploaded = false;
    },
    openModal(docType) {
      if (!this.uploadedDocument) {
        this.documentNotUploaded = true;
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("validation_errors.file_required")
        );
        return;
      }

      if (this.isAdminJobInProgress) {
        if (this.jobDetails.status === ORDER_STATUSES[3].STATUS) {
          this.$store.dispatch(
            "notifications/SHOW_ERROR_NOTIFICATION",
            this.$t("notifications.uneditable_job_docs")
          );
          return;
        }
      }

      this.confirmUploadDocument = true;
      this.uploadedDocType = docType;
    },
    closeModal() {
      this.confirmUploadDocument = false;
      this.$refs.fileUploader.clearSelectedFile();
      this.uploadedDocument = null;
      this.uploadedDocType = null;
    },
    uploadFile() {
      if (this.isAdminJobInProgress) {
        let formData = new FormData();
        formData.append("file", this.uploadedDocument);
        let params = {
          jobId: this.jobDetails.id,
          payload: formData,
        };
        this.$store
          .dispatch("contractor/UPLOAD_EDITED_DOCUMENT", params)
          .then(() => {
            this.confirmUploadDocument = false;
            this.$emit("updateJob");
          })
          .catch((error) => {
            this.$store.dispatch(
              "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
              error
            );
          })
          .finally(() => {
            this.uploadedDocType = null;
          });
      } else {
        let formData = new FormData();
        formData.append("file", this.uploadedDocument);
        formData.append("order_id", this.jobDetails.id);
        formData.append("document_type", this.uploadedDocType);

        this.$store
          .dispatch("admin/UPLOAD_FILE", formData)
          .then(() => {
            this.confirmUploadDocument = false;
            this.$emit("updateJob");
          })
          .catch((error) => {
            this.$store.dispatch(
              "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
              error
            );
          })
          .finally(() => {
            this.uploadedDocument = null;
            this.documentNotUploaded = false;
            this.uploadedDocType = null;
          });
      }
    },
    handleDownloadFile(fileId, fileName) {
      if (!fileId || !fileName) {
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("notifications.not_file_manipulate")
        );
        return;
      }

      this.downloadFile(fileId, fileName);
    },
    deleteFile(fileId) {
      if (!fileId) {
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("notifications.not_file_manipulate")
        );
        return;
      }

      let params = {
        name: fileId,
      };

      this.$store
        .dispatch("admin/DELETE_FILE", params)
        .then(() => {
          this.$emit("updateJob");
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        });
    },
    editJob(value, field) {
      let params = {
        payload: {},
        jobId: this.jobId,
      };

      switch (field) {
        case EDITING_FIELD.CONTRACTOR:
          {
            params.payload["contractor_id"] = value.id;
          }
          break;
        case EDITING_FIELD.STATUS:
          {
            params.payload["status"] = value.STATUS;
          }
          break;
        case EDITING_FIELD.RATE:
          {
            params.payload["rate_id"] = value.id;
          }
          break;
        case EDITING_FIELD.WORD_COUNT:
          {
            params.payload["word_count"] = value;
          }
          break;
        case EDITING_FIELD.ACADEMIC_FIELD:
          {
            params.payload["academic_field"] = value;
          }
          break;
        case EDITING_FIELD.PAYOUT:
          {
            if (value.length) {
              let amount = value.substring(1, value.length);
              params.payload["payout"] = +amount;
            }
          }
          break;
      }

      this.$store
        .dispatch("admin/EDIT_JOB", params)
        .then(() => {
          this.$emit("updateJob");
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        });
    },
    handleForm(value, { resetForm }) {
      let params = {
        payload: value,
        jobId: this.jobId,
      };

      this.$store
        .dispatch("admin/ADD_NOTE_TO_JOB", params)
        .then(() => {
          this.$emit("updateJob");
          resetForm();
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        });
    },
    reportUneditable(values) {
      let params = {
        jobId: this.jobId,
        payload: values,
      };

      this.$store
        .dispatch("contractor/REPORT_UNEDITABLE_DOCUMENT", params)
        .then(() => {
          this.$emit("updateJob");
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        });
    },
    handleChangeField($event, editingField) {
      let updatedField = $event.currentTarget.textContent;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.editJob(updatedField, editingField);
      }, 1500);
    },
    showCopyAlert({ payment_intent_id }) {
      if (!payment_intent_id) {
        return;
      }

      this.$store.dispatch(
        "notifications/SHOW_SUCCESS_NOTIFICATION",
        this.$t("notifications.clipboard")
      );
    },
  },
  created() {
    this.setCurentStatusForOrder();

    if (!this.orderRates.length) {
      this.getRates();
    } else {
      this.setSelectedRates();
    }

    if (!this.contractorsList.length) {
      this.getContractors();
    } else {
      this.setSelectedContractors();
    }
  },
  mounted() {
    // This interval for rerender component once per minute
    // to start the timers
    this.componentInterval = setInterval(() => {
      this.$forceUpdate();
    }, 60000);
  },
  beforeUnmount() {
    clearInterval(this.componentInterval);
  },
};
</script>
