<template>
  <div class="unassigned-jobs-table">
    <div class="unassigned-jobs-table__header row-start f-12-white f-bold">
      <div class="lg-2 p-4 s-align-5">{{ $t("admin.user_table.name") }}</div>
      <div class="lg-3 p-4 s-align-5">{{ $t("admin.user_table.email") }}</div>
      <div class="lg-2 p-4 s-align-5">{{ $t("admin.user_table.phone") }}</div>
      <div class="lg-2 p-4 s-align-5">{{ $t("admin.user_table.role") }}</div>
      <div class="lg-2 p-4 s-align-5">{{ $t("admin.user_table.created") }}</div>
      <div class="lg-1 white-cell"></div>
    </div>
    <div
      :class="[
        'unassigned-jobs-table__body row-start f-12-black',
        { 'bg-grey-2': user.is_blocked },
      ]"
      v-for="user in usersList"
      :key="user.id"
    >
      <div class="lg-2 p-4 s-align-5 bb-black bl-black br-black">
        {{ fullName(user) }}
      </div>
      <div class="lg-3 p-4 s-align-5 bb-black br-black">{{ user.email }}</div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        <template v-if="user.phone">{{ user.phone }}</template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{ user.role ? user.role : "—" }}
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{ dateFormat(user.created_at) }}
      </div>
      <div class="lg-1 p-4 row-between white-cell" @click.stop>
        <button
          class="transparent-button"
          @click="actionWithUser(user, EVENTS_NAME.EDIT)"
        >
          <edit-icon size="16" />
        </button>
        <button
          class="transparent-button"
          @click="actionWithUser(user, EVENTS_NAME.DELETE)"
        >
          <trash-icon />
        </button>
        <button
          class="transparent-button"
          @click="actionWithUser(user, EVENTS_NAME.BLOCK)"
        >
          <stop-icon size="16" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EditIcon, TrashIcon, StopIcon } from "@/icons";
import { dateFormat, fullName } from "@/helpers/commonHelpers";

const EVENTS_NAME = {
  EDIT: "editUser",
  DELETE: "deleteUser",
  BLOCK: "blockUser",
};

export default {
  name: "UsersTable",
  components: {
    EditIcon,
    TrashIcon,
    StopIcon,
  },
  props: {
    usersList: {
      type: Array,
      required: true,
    },
  },
  emits: [EVENTS_NAME.EDIT, EVENTS_NAME.DELETE, EVENTS_NAME.BLOCK],
  data() {
    return {
      EVENTS_NAME,
    };
  },
  methods: {
    // Methods imported from commonHelpers.js
    dateFormat,
    fullName,
    actionWithUser({ id }, eventName) {
      this.$emit(eventName, id);
    },
  },
};
</script>
