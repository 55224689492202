<template>
  <modal-window @close="$emit('closeModal')">
    <template #title>
      <h1 class="f-20-black f-bold mb-2">
        {{ $t("uploading_modal.important") }}<br />
        {{ $t("uploading_modal.title") }}
      </h1>
    </template>
    <template #body>
      <div class="row-start f-16-black">
        <p class="mb-3">{{ $t("uploading_modal.p_1") }}</p>
        <p class="mb-3">{{ $t("uploading_modal.p_2") }}</p>
        <ol class="pl-40 mb-3">
          <li>{{ $t("uploading_modal.li_1") }}</li>
          <li>{{ $t("uploading_modal.li_2") }}</li>
          <li>{{ $t("uploading_modal.li_3") }}</li>
        </ol>
        <p>{{ $t("uploading_modal.p_3") }} {{ $t("uploading_modal.p_4") }}</p>
      </div>
    </template>
    <template #footer>
      <div class="row-center mt-20">
        <button class="mr-6 secondary-button" @click="$emit('uploadFile')">
          {{ $t("btn.agree") }}
        </button>
        <button class="secondary-button" @click="$emit('closeModal')">
          {{ $t("btn.cancel") }}
        </button>
      </div>
    </template>
  </modal-window>
</template>

<script>
import ModalWindow from "./ModalWindow.vue";

export default {
  name: "UploadFileModal",
  components: {
    ModalWindow,
  },
  emits: ["closeModal", "uploadFile"],
};
</script>
