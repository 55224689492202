import {
  ifAuthenticated,
  validateUserManagmentRoute,
} from "@/helpers/routerHelpers";
import { ROLES } from "@/constants/commonConstants";

const adminRoutes = [
  {
    path: "/all-jobs",
    name: "AllJobs",
    component: () =>
      import(/* webpackChunkName: "AllJobs" */ "@/views/admin/AllJobs.vue"),
    meta: {
      layout: "main",
      permission: {
        roles: [ROLES.ADMIN],
        noAccessRedirect: "/security/role/forbidden",
      },
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/job/:jobId(\\d+)",
    name: "JobDetails",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "JobDetails" */ "@/views/admin/JobDetails.vue"
      ),
    meta: {
      layout: "main",
      permission: {
        roles: [ROLES.ADMIN],
        noAccessRedirect: "/security/role/forbidden",
      },
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/users-managment/:userRole",
    name: "UsersManagment",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "UsersManagment" */ "@/views/admin/UsersManagment.vue"
      ),
    meta: {
      layout: "main",
      permission: {
        roles: [ROLES.ADMIN],
        noAccessRedirect: "/security/role/forbidden",
      },
    },
    beforeEnter: [ifAuthenticated, validateUserManagmentRoute],
  },
  {
    path: "/add-contractor",
    name: "AddContractor",
    component: () =>
      import(
        /* webpackChunkName: "AddContractor" */ "@/views/admin/AddContractor.vue"
      ),
    meta: {
      layout: "main",
      permission: {
        roles: [ROLES.ADMIN],
        noAccessRedirect: "/security/role/forbidden",
      },
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/edit-user/:userRole/:userId(\\d+)",
    name: "EditUser",
    props: true,
    component: () =>
      import(/* webpackChunkName: "EditUser" */ "@/views/admin/EditUser.vue"),
    meta: {
      layout: "main",
      permission: {
        roles: [ROLES.ADMIN],
        noAccessRedirect: "/security/role/forbidden",
      },
    },
    beforeEnter: [ifAuthenticated, validateUserManagmentRoute],
  },
];

export default adminRoutes;
