<template>
  <svg :width="width" :height="height">
    <rect fill="#fff" :width="width" :height="height" />
    <circle fill="#bc002d" :cx="width / 2" :cy="height / 2" r="4" />
  </svg>
</template>

<script>
export default {
  name: "JapanFlagIcon",
  props: {
    width: {
      type: [Number, String],
      default: 25,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
  },
};
</script>
