<template>
  <svg
    viewBox="0 0 171.67420959472656 193.37057495117188"
    :width="width"
    :height="height"
    class="trash-icon"
  >
    <rect
      id="ee-background"
      x="0"
      y="0"
      width="171.67420959472656"
      height="193.37057495117188"
      style="fill: white; fill-opacity: 0; pointer-events: none"
    />
    <g
      transform="matrix(1.9567859172821045, 0, 0, 1.9567859172821045, 826.889762878418, -426.5793151855469)"
    >
      <path
        d="M-340,235h-23.9v-3.6c0-6-4.9-10.9-10.9-10.9h-8.2c-6,0-10.9,4.9-10.9,10.9v3.6H-418c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5h78c1.9,0,3.5-1.6,3.5-3.5S-338.1,235-340,235z M-387.1,231.4c0-2.2,1.8-4,4-4h8.2c2.2,0,4,1.8,4,4v3.6h-16.2V231.4z"
      />
      <path
        d="M-346.7,249.5c-1.9,0-3.5,1.6-3.5,3.5v50.3c0,2.9-2.3,5.2-5.2,5.2h-47.1c-2.9,0-5.2-2.3-5.2-5.2V253c0-1.9-1.6-3.5-3.5-3.5c-1.9,0-3.5,1.5-3.5,3.5v50.3c0,6.7,5.5,12.2,12.2,12.2h47.1c6.7,0,12.2-5.5,12.2-12.2V253C-343.3,251-344.8,249.5-346.7,249.5z"
      />
      <path
        d="M-391.6,296.6V253c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.5-3.5,3.5v43.7c0,1.9,1.6,3.5,3.5,3.5S-391.6,298.6-391.6,296.6z"
      />
      <path
        d="M-375.5,296.6V253c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.5-3.5,3.5v43.7c0,1.9,1.6,3.5,3.5,3.5S-375.5,298.6-375.5,296.6z"
      />
      <path
        d="M-359.4,296.6V253c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5v43.7c0,1.9,1.6,3.5,3.5,3.5C-360.9,300.1-359.4,298.6-359.4,296.6z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "TrashIcon",
  props: {
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 15,
    },
  },
};
</script>
