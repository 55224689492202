<template>
  <svg viewBox="0 0 29 27" :width="width" :height="height" class="upload-icon">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6709 19V5.76777L20.7776 9.88389L22.9708 8.11612L14.1201 0.982239L5.26932 8.11612L7.46252 9.88389L12.5692 5.76777V19H15.6709ZM28.0775 24V12.75H24.9759V24H3.26426V12.75H0.162598V24C0.162598 25.3807 1.55126 26.5 3.26426 26.5H24.9759C26.6889 26.5 28.0775 25.3807 28.0775 24Z"
    />
  </svg>
</template>

<script>
export default {
  name: "UploadIcon",
  props: {
    width: {
      type: [Number, String],
      default: 29,
    },
    height: {
      type: [Number, String],
      default: 27,
    },
  },
};
</script>
