/* eslint-disable no-empty-pattern */
import {
  GET_USER_INFO,
  SET_USER_INFO,
  UPDATE_USER_INFO,
  UPDATE_USER_EMAIL,
  UPDATE_USER_PASSWORD,
  GET_CONTRACTORS,
  GET_CLIENTS,
  GET_USER_BY_ID,
  DELETE_USER,
  BLOCK_USER,
} from "../actions/user";
import api from "@/api/api.js";
import { ROLES } from "@/constants/commonConstants";

export default {
  namespaced: true,
  state: {
    authUser: {},
  },
  getters: {
    getUserInfo: (state) => state.authUser,
    getUserId: (state) => state.authUser?.id,
    getUserRole: (state) => state.authUser?.role,
    getUserLocale: (state) => state.authUser.lang,
  },
  mutations: {
    [SET_USER_INFO](state, payload) {
      state.authUser = payload;
      localStorage.setItem("user-id", payload.id);
    },
  },
  actions: {
    [GET_USER_INFO]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .get("/me", payload)
          .then((response) => response.data.data)
          .then((data) => {
            commit(SET_USER_INFO, data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_USER_INFO]({}, { userId, payload, whomEditing }) {
      let endpoint = "";

      switch (whomEditing) {
        case ROLES.CONTRACTOR:
          endpoint = `/edit-contractor/${userId}`;
          break;
        case ROLES.CLIENT:
        case ROLES.ADMIN:
          endpoint = `/edit-user/${userId}`;
          break;
      }

      return new Promise((resolve, reject) => {
        api
          .put(endpoint, payload)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_USER_EMAIL]({}, { payload }) {
      return new Promise((resolve, reject) => {
        api
          .put(`/change-email`, payload)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_USER_PASSWORD]({}, { userId, payload }) {
      return new Promise((resolve, reject) => {
        api
          .put(`/change-password/${userId}`, payload)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_CONTRACTORS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/contractors`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_CLIENTS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/clients`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_USER_BY_ID]({}, userId) {
      return new Promise((resolve, reject) => {
        api
          .get(`/user/${userId}`)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DELETE_USER]({}, userId) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/delete-user/${userId}`)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [BLOCK_USER]({}, userId) {
      return new Promise((resolve, reject) => {
        api
          .put(`/block-user/${userId}`)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
