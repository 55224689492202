export default {
  "site_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eneo"])},
  "Homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "Japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "login_head_highlighting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "login_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to your account"])},
  "register_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register an account"])},
  "forgot_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your e-mail?"])},
  "click_to_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to contact customer support"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "click_to_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to reset your password"])},
  "work_with_eneo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested in working for Eneo? We offer generous rates with a convenient work structure, perfect for busy academics."])},
  "more_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here for more information."])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "my_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Details"])},
  "login_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login details"])},
  "personal_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal details"])},
  "my_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
  "customer_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer support"])},
  "no_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No information"])},
  "account_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been created!"])},
  "verify_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your email and click on the confirmation link to verify your email address."])},
  "check_spam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have not received a confirmation email, please check your spam folder."])},
  "file_uploader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload or drag and drop"])},
  "allowed_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported: DOC, DOCX, ODF"])},
  "my_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My jobs"])},
  "all_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Jobs"])},
  "unassigned_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned jobs"])},
  "assigned_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Jobs"])},
  "rejected_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected Jobs"])},
  "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have access to view this page."])},
  "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found."])},
  "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
  "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished Jobs"])},
  "approved_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting Payout"])},
  "contractor_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractors accounts"])},
  "customer_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer accounts"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "original_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original file"])},
  "edited_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited file"])},
  "add_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add note"])},
  "read_accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I read and accept"])},
  "terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "verify_email_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email verification link has expired."])},
  "resend_verify_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your email or resend your verification email by clicking the button below."])},
  "register_billing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add your billing address to finish registration"])},
  "address_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Details"])},
  "footer": {
    "gua_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guaranteed Security"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us:"])},
    "contact_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday - Friday 10:00 AM - 8:00 PM AEST"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright© 2021 Eneo Proofreading"])}
  },
  "input_fields": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "institution_or_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution or Company"])},
    "institution_or_company_opt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution or Company (Optional)"])},
    "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal address"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact phone"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "doc_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document type"])},
    "academic_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic or corporate field"])},
    "word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word count"])},
    "turnaround_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turnaround time"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "abn_or_tax_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABN or Tax Reference"])},
    "bank_acc_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
    "bsb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSB"])},
    "bank_acc_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "standing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Standing"])},
    "education_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest level attained"])},
    "education_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attained From"])},
    "first_editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First editing"])},
    "field_of_expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field of expertise"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note message"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line 1 (e.g., street, PO box, or company name)"])},
    "line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line 2 (e.g., apartment, suite, unit, or building)"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP or postal code"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State (state, country, province or region)"])}
  },
  "btn": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "change_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change email"])},
    "update_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
    "order_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Now"])},
    "accept_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept work"])},
    "reject_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject work"])},
    "accept_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept job"])},
    "to_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to profile"])},
    "download_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download document"])},
    "upload_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload finished document"])},
    "contact_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact manager"])},
    "report_un-editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report un-editable"])},
    "add_contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add contractor"])},
    "send_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invitation"])},
    "download_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file"])},
    "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file"])},
    "upload_finished_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload finished file"])},
    "delete_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete file"])},
    "add_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add note"])},
    "to_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to orders"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I agree"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, cancel upload"])},
    "pay_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay order"])},
    "resend_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend verification email"])},
    "cancel_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel order"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "validation_errors": {
    "required_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is a required field"])},
    "email_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be a valid email"])},
    "field_too_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be at least 8 characters"])},
    "phone_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not valid"])},
    "confirm_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must match"])},
    "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be an integer"])},
    "file_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document file must be uploaded"])},
    "letters_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must only contain letters"])},
    "word_count_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word count field cannot be empty"])},
    "max_word_count_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum order of 80,000 words. Please contact us."])},
    "min_word_count_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum order of 500 words. Please contact us."])}
  },
  "notifications": {
    "upd_profile_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile has been updated successfully!"])},
    "upd_email_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email has been changed successfully!"])},
    "upd_password_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been changed successfully!"])},
    "register_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been registered! \n Please check your email to confirm your email address!"])},
    "email_verified_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email has been verified successfully!"])},
    "not_allowed_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file must be a file of type: doc, docx, odf."])},
    "contractor_reg_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account for contractor has been registered!"])},
    "contractor_upd_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractor details has been updated successfully!"])},
    "customer_upd_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer details has been updated successfully!"])},
    "uneditable_job_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading and downloading documents for job with the status \n 'In Progress Pending Senior Editor' is prohibited"])},
    "not_file_manipulate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file operation is not available."])},
    "payment_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment successful."])},
    "uneditable_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already submitted a un-editable report for this order."])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reference has been copied to the clipboard."])},
    "order_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order has been canceled."])},
    "order_canceled_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order cannot be cancelled in its current status. Please contact customer support."])},
    "country_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country is a required field"])},
    "email_is_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account registered with this email already exists"])}
  },
  "order": {
    "form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure"])},
    "form_title_highlighting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proofreading"])},
    "price_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services and prices"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your price estimate"])},
    "coupon_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a coupon?"])},
    "order_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders in Progress"])},
    "order_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Orders"])},
    "orders_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have orders yet"])},
    "reson_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add rejection reason"])},
    "note_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add note text"])},
    "table": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "order_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Reference"])},
      "payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment date"])},
      "total_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "eta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETA"])},
      "completion_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion date"])},
      "approval_timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time left for approve"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
      "word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word count"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected service"])},
      "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded document"])},
      "edited_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited document"])},
      "doc_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not yet uploaded"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download invoice"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Customer Support"])}
    }
  },
  "order_status": {
    "pending_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Pending Payment"])},
    "pending_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress Pending Editor"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress Editing"])},
    "uneditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress Pending Senior Editor"])},
    "pending_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled Pending Refund"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled Complete"])},
    "pending_approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulfilled Pending Approval"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-edit Requested Pending Senior Editor"])},
    "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulfilled Approved"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])}
  },
  "doc_types": {
    "academic_manuscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Manuscript"])},
    "grant_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant Proposal"])},
    "admission_essay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Essay"])},
    "undergraduate_essay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undergraduate Essay"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business and Corporate"])}
  },
  "turnaround_time": {
    "standart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
    "platinum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum"])}
  },
  "services": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "return_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return time"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Per Word"])},
    "standart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "standart_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Least urgent)"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
    "premium_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Urgent)"])},
    "platinum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum"])},
    "platinum_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Highest urgency)"])},
    "standart_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 7 Days"])},
    "premium_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 72 Hours"])},
    "platinum_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 24 Hours"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that these prices are for our proofreading service, which does not include substantive editing. Read more about what our services include on our services page. Tax not included. Terms and conditions apply."])},
    "tax_appended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus Tax"])},
    "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Words"])},
    "word_within": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Words: Within"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "asap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASAP!"])}
  },
  "table_pagination": {
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
    "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display records"])}
  },
  "contractor": {
    "account_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Details"])},
    "bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
    "edu_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education history"])},
    "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change details that require verification by management (e.g.highest education level attained)"])},
    "click_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please click here to contact a manager"])},
    "jobs_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs list is empty!"])},
    "active_job_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have work in progress yet"])}
  },
  "jobs": {
    "unassigned_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned Jobs"])},
    "job_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job order detail"])},
    "table": {
      "date_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date added"])},
      "finish_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time left to finish"])},
      "accept_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time left to accept"])},
      "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
      "paid_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid amount"])},
      "word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word count"])},
      "academic_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic or corporate field"])},
      "date_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date accepted"])},
      "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date completed"])},
      "submit_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time left to submit"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "contractor_time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractor time left"])},
      "time_finalize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time left to finalise"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
      "rejected_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected by"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log"])},
      "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
      "not_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order was not accepted"])}
    }
  },
  "admin": {
    "add_contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new contractor"])},
    "edit_contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractors - Edit details"])},
    "edit_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer - Edit details"])},
    "contractor_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of contractors is empty"])},
    "clients_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of customers is empty"])},
    "user_table": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])}
    }
  },
  "stripe": {
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you."])},
    "succes_paid_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order has been successfully paid."])},
    "failed_paid_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Not Processed. Please check order."])},
    "reciept_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email with your reciept."])}
  },
  "uploading_modal": {
    "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work order finalisation."])},
    "p_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may be penalised. Double check each point!"])},
    "p_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking 'Yes' you agree that this work order is complete and the file you have selected is:"])},
    "li_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The correct document relating to this work order."])},
    "li_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully revised and annotated with no errors of any kind."])},
    "li_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliant with every work order stipulation in your contract."])},
    "p_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should the client request the document be re-edited due to a violation of any of the above your account with Eneo can be suspended or terminated with no notice. If the client requests a re-edit by another subcontractor your payout may be reduced to cover this cost."])},
    "p_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At managerial discretion, should your work be deemed unacceptable to the degree of negligence you may not be paid for this work order."])}
  },
  "files": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job files"])},
    "no_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not deleted"])},
    "table": {
      "date_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date added"])},
      "date_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date deleted"])},
      "filenane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
      "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log"])}
    }
  }
}