import { createRouter, createWebHistory } from "vue-router";
import authRoutes from "@/views/auth/authRoutes";
import customerRoutes from "@/views/customer/customerRoutes";
import adminRoutes from "@/views/admin/adminRoutes";
import store from "@/store/index.js";
import { checkPermission } from "@/components/accessControl/checkPermission";
import { ifAuthenticated } from "@/helpers/routerHelpers";
import { ROLES } from "@/constants/commonConstants";

const routes = [
  ...authRoutes,
  ...customerRoutes,
  ...adminRoutes,
  {
    path: "/",
    name: "OrderPage",
    component: () =>
      import(
        /* webpackChunkName: "OrderPage" */ "@/views/customer/OrderPage.vue"
      ),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/profile",
    name: "MyDetails",
    component: () =>
      import(
        /* webpackChunkName: "MyDetails" */ "@/views/commonViews/MyDetailsPage.vue"
      ),
    meta: {
      layout: "main",
      permission: {
        roles: [ROLES.CLIENT, ROLES.CONTRACTOR, ROLES.ADMIN],
        noAccessRedirect: "/security/role/forbidden",
      },
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/unassigned-jobs",
    name: "UnassignedJobs",
    component: () =>
      import(
        /* webpackChunkName: "UnassignedJobs" */ "@/views/commonViews/UnassignedJobs.vue"
      ),
    meta: {
      layout: "main",
      permission: {
        roles: [ROLES.CONTRACTOR, ROLES.ADMIN],
        noAccessRedirect: "/security/role/forbidden",
      },
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/my-jobs",
    name: "MyJobs",
    component: () =>
      import(/* webpackChunkName: "MyJobs" */ "@/views/commonViews/MyJobs.vue"),
    meta: {
      layout: "main",
      permission: {
        roles: [ROLES.CONTRACTOR, ROLES.ADMIN],
        noAccessRedirect: "/security/role/forbidden",
      },
    },
    beforeEnter: ifAuthenticated,
  },
  // Not access page
  {
    path: "/security/role/forbidden",
    name: "HasNotAccess",
    component: () =>
      import(
        /* webpackChunkName: "hasNotAccess" */ "@/views/HasNotAccessToRoute.vue"
      ),
    meta: {
      layout: "main",
    },
  },
  // Page for 404 error
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/views/404.vue"),
    meta: {
      layout: "main",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const isAuth = store.getters["auth/isAuth"];
  const getUserIfNotReceived = () => {
    const userRole = store.getters["user/getUserRole"];

    if (!userRole && isAuth) {
      return store.dispatch("user/GET_USER_INFO").then();
    }
  };

  await getUserIfNotReceived();

  // If there are no permissions to check then proceed
  if (!to.meta.permission) return next();

  const { roles = [], config = {} } = to.meta.permission;
  if (!roles.length) return next();
  const hasAccess = checkPermission(roles, config);

  if (hasAccess) {
    return next();
  }

  if (isAuth) {
    // No access!
    next(to.meta.permission?.noAccessRedirect || "/security/role/forbidden");
  } else {
    next("/auth");
  }
});

export default router;
