<template>
  <div class="main-layout">
    <app-header></app-header>
    <div class="main-layout_content">
      <slot />
    </div>
    <app-footer />
  </div>
</template>

<script>
import { AppHeader, AppFooter } from "@/components";

export default {
  name: "MainLayout",
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
