<template>
  <div class="w-100">
    <label
      class="upload-drop-area column-center pointer"
      ref="droparea"
      @dragenter.stop.prevent="dropActive"
      @dragover.stop.prevent="dropActive"
      @dragleave.stop.prevent="dropInActive"
      @drop.stop.prevent="dropInActive"
    >
      <input
        type="file"
        name="file_upload"
        ref="uploadInput"
        accept=".doc,.docx,.odf"
        @change="fileInputHandler"
      />
      <div class="column-center">
        <upload-icon class="i-white mb-6" />
        <span class="f-14-white t-center" v-if="!fileIsUploaded">
          {{ $t("file_uploader") }} <br />
          {{ $t("allowed_files") }}
        </span>
        <span class="f-14-white t-center" v-else>{{ uploadedFile }}</span>
      </div>
    </label>
  </div>
</template>

<script>
import { UploadIcon } from "@/icons";

export default {
  name: "FileUploader",
  components: { UploadIcon },
  props: {
    fileForUploading: {
      type: Object,
      required: false,
    },
  },
  emits: ["uploadedFiles"],
  data() {
    return {
      uploadedFiles: [],
      allowedFileTypes: [".doc", ".docx", ".odf"],
    };
  },
  computed: {
    fileIsUploaded() {
      return !!this.uploadedFiles.length;
    },
    uploadedFile() {
      return !!this.uploadedFiles.length && this.uploadedFiles[0].name;
    },
  },
  methods: {
    dropActive() {
      this.$refs.droparea.style.backgroundColor = "rgba(59, 59, 59, 0.3)";
    },
    dropInActive() {
      this.$refs.droparea.style.backgroundColor = "#3B3B3B";
    },
    fileInputHandler() {
      this.uploadedFiles = [];
      if (!this.$refs.uploadInput.files.length) {
        return false;
      }

      this.prepeareFilesForUploading(this.$refs.uploadInput.files);
    },
    dropHandler(event) {
      this.uploadedFiles = [];
      let files = event.dataTransfer.files;
      let allowedFileType = false;

      if (!files.length) {
        return false;
      }

      for (let i = 0; i < this.allowedFileTypes.length; i++) {
        if (!files[0].name.includes(this.allowedFileTypes[i])) {
          allowedFileType = false;
        } else {
          allowedFileType = true;
          break;
        }
      }

      if (allowedFileType) {
        this.prepeareFilesForUploading(files);
      } else {
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("notifications.not_allowed_file")
        );
      }
    },
    prepeareFilesForUploading(files) {
      this.uploadedFiles.push(files[0]);
      this.$emit("uploadedFiles", this.uploadedFiles);
    },
    clearSelectedFile() {
      this.uploadedFiles = [];
    },
  },
  created() {
    if (this.fileForUploading) {
      this.uploadedFiles.push(this.fileForUploading);
    }
  },
  mounted() {
    if (this.$refs.droparea) {
      this.$refs.droparea.addEventListener("drop", this.dropHandler, false);
    }
  },
  beforeUnmount() {
    this.$refs.droparea.removeEventListener("drop", this.dropHandler, false);
  },
};
</script>
