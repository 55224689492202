<template>
  <div class="w-100">
    <p v-if="showLabel" class="mb-4 f-12-black f-bold">
      {{ $t("input_fields.country") }}
    </p>
    <vue-select
      :class="[
        'w-100',
        selectClass,
        // eslint-disable-next-line prettier/prettier
        { invalid: countriesInvalidField },
      ]"
      :options="countries"
      label-by="label"
      v-model="selectedCountry"
      close-on-select
      @update:modelValue="selectCountry"
      :placeholder="$t('input_fields.country')"
    >
      <template #label="{ selected }" v-if="selectClass === 'vue-select--gray'">
        <template v-if="!selected">
          <p class="f-14-grey">
            {{ $t("input_fields.country") }}
          </p>
        </template>
      </template>
    </vue-select>
  </div>
</template>

<script>
const countries = require("@dior/i18n-iso-countries");
countries.registerLocale(require("@dior/i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("@dior/i18n-iso-countries/langs/ja.json"));

export default {
  name: "CountrySelect",
  props: {
    countryCode: {
      type: String,
      required: false,
    },
    showLabel: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    selectClass: {
      type: String,
      default: () => {
        return "vue-select--no-rounded";
      },
    },
  },
  data() {
    return {
      selectedCountry: "",
      countriesInvalidField: false,
    };
  },
  computed: {
    countries() {
      const list = countries.getNames(this.$i18n.locale, {
        select: "official",
      });
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }));
    },
  },
  methods: {
    selectCountry(val) {
      this.countriesInvalidField = val?.value ? false : true;
    },
    validateSelectCountryField() {
      if (!this.selectedCountry) {
        this.countriesInvalidField = true;
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("notifications.country_required")
        );
        return { valid: false };
      }

      return {
        selectedCountry: this.selectedCountry,
        valid: true,
      };
    },
  },
  created() {
    if (this.countryCode) {
      this.selectedCountry = this.countries.find(
        (c) => c.value === this.countryCode
      );
    }
  },
};
</script>
