<template>
  <Form
    class="row-start mt-40"
    :validation-schema="userValidationSchema"
    :initial-values="formValues"
    @submit="handleForm"
    @invalid-submit="handleValidationError"
    v-slot="{ errors }"
  >
    <div class="lg-12 mb-20 f-12-black f-bold">
      {{ $t("contractor.account_details") }}
    </div>
    <div class="row-start mb-40" v-if="userRole === ROLES.CONTRACTOR">
      <text-input
        :label="$t('input_fields.role')"
        name="role"
        id="role"
        :errors="errors"
        :inputContainerClass="'lg-4 pr-5'"
        fieldType="text"
        :placeholder="$t('input_fields.role')"
      />
      <text-input
        :label="$t('input_fields.standing')"
        name="standing"
        id="standing"
        :errors="errors"
        :inputContainerClass="'lg-4 pl-5'"
        fieldType="text"
        :placeholder="$t('input_fields.standing')"
      />
    </div>
    <div class="row-start mb-40">
      <div class="lg-6 pr-5">
        <text-input
          :label="$t('input_fields.title')"
          name="title"
          id="title"
          :errors="errors"
          :inputContainerClass="'lg-2'"
          fieldType="text"
          :placeholder="$t('input_fields.title')"
        />
        <text-input
          :label="$t('input_fields.first_name')"
          name="first_name"
          id="first_name"
          :errors="errors"
          :inputContainerClass="'lg-5 pl-20'"
          fieldType="text"
          :placeholder="$t('input_fields.first_name')"
        />
        <text-input
          :label="$t('input_fields.last_name')"
          name="last_name"
          id="last_name"
          :errors="errors"
          :inputContainerClass="'lg-5 pl-20'"
          fieldType="text"
          :placeholder="$t('input_fields.last_name')"
        />
      </div>
      <div class="lg-6 pl-5">
        <v-date-picker
          class="relative form-input-container lg-3"
          v-model="date"
          mode="date"
          color="red"
          :masks="masks"
          :locale="$i18n.locale"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <label for="birth">{{ $t("input_fields.birthday") }}</label>
            <input
              :value="inputValue"
              id="birth"
              class="form-input-field"
              :placeholder="$t('input_fields.birthday')"
              v-on="inputEvents"
            />
          </template>
        </v-date-picker>
        <text-input
          v-if="userRole === ROLES.CONTRACTOR"
          :label="$t('input_fields.abn_or_tax_reference')"
          name="abn_or_tax_reference"
          id="abn_or_tax_reference"
          :errors="errors"
          :inputContainerClass="'lg-9 pl-20'"
          fieldType="text"
          :placeholder="$t('input_fields.abn_or_tax_reference')"
        />
        <text-input
          v-else
          :label="$t('input_fields.institution_or_company_opt')"
          name="institution"
          id="institution"
          :errors="errors"
          :inputContainerClass="'lg-9 pl-20'"
          fieldType="text"
          :placeholder="$t('input_fields.institution_or_company')"
        />
      </div>
    </div>
    <div class="row-start mb-40">
      <div class="lg-6">
        <div class="lg-6">
          <text-input
            :label="$t('input_fields.phone')"
            name="phone"
            id="phone"
            :errors="errors"
            :inputContainerClass="'lg-12'"
            fieldType="phone"
            :placeholder="$t('input_fields.phone')"
            v-maska="phoneMaska"
          />
        </div>
      </div>
    </div>
    <div class="row-start">
      <div class="lg-12 mb-20 f-12-black f-bold">
        {{ $t("address_details") }}
      </div>
    </div>
    <div class="row-start">
      <div class="lg-3 pr-5">
        <country-select :countryCode="countryForSelect" ref="countrySelect" />
      </div>
      <div class="lg-3 pl-5 pr-5">
        <text-input
          name="city"
          id="city"
          :label="$t('input_fields.city')"
          :placeholder="$t('input_fields.city')"
          :errors="errors"
          :inputContainerClass="'lg-12'"
          fieldType="text"
        />
      </div>
      <div class="lg-6 pl-5 pr-5">
        <text-input
          name="line_1"
          id="line_1"
          :label="$t('input_fields.line_1')"
          :placeholder="$t('input_fields.line_1')"
          :errors="errors"
          :inputContainerClass="'lg-12'"
          fieldType="text"
        />
      </div>
    </div>
    <div class="row-start mt-40 mb-40">
      <div class="lg-6 pr-5">
        <text-input
          name="line_2"
          id="line_2"
          :label="$t('input_fields.line_2')"
          :placeholder="$t('input_fields.line_2')"
          :errors="errors"
          :inputContainerClass="'lg-12'"
          fieldType="text"
        />
      </div>
      <div class="lg-2 pr-5 pl-5">
        <text-input
          name="zip"
          id="zip"
          :label="$t('input_fields.zip')"
          :placeholder="$t('input_fields.zip')"
          :errors="errors"
          :inputContainerClass="'lg-12'"
          fieldType="text"
        />
      </div>
      <div class="lg-4 pl-5 pr-5">
        <text-input
          name="state"
          id="state"
          :label="$t('input_fields.state')"
          :placeholder="$t('input_fields.state')"
          :errors="errors"
          :inputContainerClass="'lg-12'"
          fieldType="text"
        />
      </div>
    </div>
    <div class="row-start mb-40" v-if="userRole === ROLES.CONTRACTOR">
      <div class="lg-12 mb-20 f-12-black f-bold">
        {{ $t("contractor.edu_history") }}
      </div>
      <div class="row-start">
        <text-input
          :label="$t('input_fields.education_level')"
          name="education_level"
          id="education_level"
          :errors="errors"
          :inputContainerClass="'lg-3 pr-5'"
          fieldType="text"
          :placeholder="$t('input_fields.education_level')"
        />
        <text-input
          :label="$t('input_fields.education_from')"
          name="education_from"
          id="education_from"
          :errors="errors"
          :inputContainerClass="'lg-4 ph-5'"
          fieldType="text"
          :placeholder="$t('input_fields.education_from')"
        />
        <text-input
          :label="$t('input_fields.first_editing')"
          name="first_editing"
          id="first_editing"
          :errors="errors"
          :inputContainerClass="'lg-2 ph-5'"
          fieldType="text"
          :placeholder="$t('input_fields.first_editing')"
        />
        <text-input
          :label="$t('input_fields.field_of_expertise')"
          name="field_of_expertise"
          id="field_of_expertise"
          :errors="errors"
          :inputContainerClass="'lg-3 pl-5'"
          fieldType="text"
          :placeholder="$t('input_fields.field_of_expertise')"
        />
      </div>
    </div>
    <div class="row-start mb-40" v-if="isCreateMode">
      <div class="lg-12 mb-20 f-12-black f-bold">
        {{ $t("login_details") }}
      </div>
      <text-input
        :label="$t('input_fields.email')"
        name="email"
        id="email"
        :errors="errors"
        :inputContainerClass="'lg-4 pr-5'"
        fieldType="email"
        :placeholder="$t('input_fields.email')"
      />
      <text-input
        :label="$t('input_fields.password')"
        name="password"
        id="password"
        :errors="errors"
        :inputContainerClass="'lg-3'"
        fieldType="password"
        :placeholder="$t('input_fields.password')"
      />
      <text-input
        :label="$t('input_fields.password_confirmation')"
        name="password_confirmation"
        id="password_confirmation"
        :errors="errors"
        :inputContainerClass="'lg-3 pl-5 pr-5'"
        fieldType="password"
        :placeholder="$t('input_fields.password_confirmation')"
      />
      <button class="lg-2 primary-button mt-auto">
        {{ $t("btn.send_invite") }}
      </button>
    </div>
    <div class="row-end mb-20" v-else>
      <button class="lg-2 primary-button">
        {{ $t("btn.save_changes") }}
      </button>
    </div>
  </Form>
  <div class="row-start" v-if="!isCreateMode">
    <div class="lg-12 mb-20 f-12-black f-bold">
      {{ $t("login_details") }}
    </div>
    <Form
      v-if="!isCreateMode && userRole === ROLES.CONTRACTOR"
      class="lg-4 pr-5"
      :validation-schema="emailValidationSchema"
      :initial-values="emailFormValues"
      @submit="handleEmailForm"
      @invalid-submit="onInvalidSubmit"
      v-slot="{ errors }"
    >
      <text-input
        :label="$t('input_fields.email')"
        name="email"
        id="email"
        :errors="errors"
        :inputContainerClass="'lg-6'"
        fieldType="email"
        :placeholder="$t('input_fields.email')"
      />
      <button class="lg-6 primary-button ml-20 mt-auto">
        {{ $t("btn.change_email") }}
      </button>
    </Form>
    <div v-else class="lg-4 column-start">
      <p class="f-12-black f-bold mb-8">{{ $t("input_fields.email") }}</p>
      <p class="f-12-black">{{ emailFormValues.email }}</p>
    </div>
    <Form
      class="lg-8 pl-5"
      :validation-schema="passwordValidationSchema"
      @submit="handlePasswordForm"
      @invalid-submit="onInvalidSubmit"
      v-slot="{ errors }"
    >
      <text-input
        :label="$t('input_fields.new_password')"
        name="password"
        id="password"
        :errors="errors"
        :inputContainerClass="'lg-6'"
        fieldType="password"
        :placeholder="$t('input_fields.new_password')"
      />
      <text-input
        :label="$t('input_fields.password_confirmation')"
        name="password_confirmation"
        id="password_confirmation"
        :errors="errors"
        :inputContainerClass="'lg-4 pl-5'"
        fieldType="password"
        :placeholder="$t('input_fields.password_confirmation')"
      />
      <button class="lg-6 primary-button ml-5 mt-auto">
        {{ $t("btn.update_pass") }}
      </button>
    </Form>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import * as yup from "yup";
import { onInvalidSubmit } from "@/helpers/commonHelpers.js";
import {
  PHONEREGEXP,
  LETTER_REGEX,
  CRUD_MODE,
  ROLES,
} from "@/constants/commonConstants";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import setLocale from "@/helpers/validatorLocalization";

export default {
  name: "UserDetailsForm",
  components: {
    Form,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    userDetail: {
      type: Object,
      required: false,
    },
    userRole: {
      type: String,
      required: true,
    },
  },
  emits: ["submitForm", "changeEmail", "changePassword"],
  data() {
    // Form validation schema
    const emailValidationSchema = yup.object().shape({
      email: yup.string().required().email(),
    });

    // Form validation schema
    const passwordValidationSchema = yup.object().shape({
      password: yup.string().required().min(8),
      password_confirmation: yup
        .string()
        .required()
        .oneOf([yup.ref("password"), null]),
    });

    return {
      ROLES,
      emailValidationSchema,
      passwordValidationSchema,
      // Initial values
      formValues: {},
      emailFormValues: {
        email: "",
      },
      date: "",
      masks: {
        input: "DD/MM/YYYY",
      },
      countryForSelect: "",
    };
  },
  computed: {
    // Dynamic Form validation schema
    userValidationSchema() {
      let commonRules = {
        title: yup.string().nullable(),
        first_name: yup
          .string()
          .required()
          .matches(LETTER_REGEX, { excludeEmptyString: true }),
        last_name: yup
          .string()
          .required()
          .matches(LETTER_REGEX, { excludeEmptyString: true }),
        phone: yup
          .string()
          .matches(PHONEREGEXP, { excludeEmptyString: true })
          .nullable()
          .notRequired(),
      };

      commonRules["city"] = yup.string().nullable();
      commonRules["line_1"] = yup.string().nullable();
      commonRules["line_2"] = yup.string().nullable();
      commonRules["zip"] = yup.string().nullable();
      commonRules["state"] = yup.string().nullable();

      if (this.userRole === ROLES.CONTRACTOR) {
        commonRules["role"] = yup.string().nullable();
        commonRules["standing"] = yup.string().nullable();
        commonRules["abn_or_tax_reference"] = yup.string().nullable();
        commonRules["education_level"] = yup.string().required();
        commonRules["education_from"] = yup.string().required();
        commonRules["first_editing"] = yup.string().required();
        commonRules["field_of_expertise"] = yup.string().required();
      } else {
        commonRules["institution"] = yup.string().nullable();
      }

      if (this.isCreateMode) {
        commonRules["email"] = yup.string().required().email();
        commonRules["password"] = yup.string().required().min(8);
        commonRules["password_confirmation"] = yup
          .string()
          .required()
          .oneOf([yup.ref("password"), null]);
      }

      return yup.object().shape(commonRules);
    },
    phoneMaska() {
      if (this.$i18n.locale === "en") {
        return "+1 (###) ###-####";
      } else {
        return "+81 (###) ####-####";
      }
    },
    isCreateMode() {
      return this.mode === CRUD_MODE.CREATE;
    },
  },
  methods: {
    // Methods imported from commonHelpers.js
    onInvalidSubmit,
    handleForm(values) {
      const countryData = this.$refs.countrySelect.validateSelectCountryField();

      if (!countryData.valid) return;

      let address = {
        country: countryData.selectedCountry.value,
        city: values.city,
        line1: values.line_1,
        line2: values.line_2,
        postal_code: values.zip,
        state: values.state,
      };
      values.address = address;

      delete values.city;
      delete values.line_1;
      delete values.line_2;
      delete values.zip;
      delete values.state;

      let formData = values;

      this.date
        ? (formData["date_of_birth"] = moment(this.date).format("DD/MM/YYYY"))
        : (formData["date_of_birth"] = null);

      this.$emit("submitForm", formData);
    },
    handleValidationError(errors) {
      this.$refs.countrySelect.validateSelectCountryField();
      this.onInvalidSubmit(errors);
    },
    handleEmailForm(values) {
      let formData = values;
      this.$emit("changeEmail", formData);
    },
    handlePasswordForm(values) {
      let formData = values;
      this.$emit("changePassword", formData);
    },
    setInitialFormValue(data) {
      const userDetails = JSON.parse(JSON.stringify(data));

      this.formValues["title"] = userDetails?.title || "";
      this.formValues["first_name"] = userDetails?.first_name || "";
      this.formValues["last_name"] = userDetails?.last_name || "";
      this.formValues["address"] = userDetails?.address || "";
      this.countryForSelect = this.formValues?.address?.country || "";
      this.formValues["phone"] = userDetails?.phone || null;
      this.formValues["city"] = data?.address?.city || "";
      this.formValues["line_1"] = data?.address?.line1 || "";
      this.formValues["line_2"] = data?.address?.line2 || "";
      this.formValues["state"] = data?.address?.state || "";
      this.formValues["zip"] = data?.address?.postal_code || "";

      if (this.userRole === ROLES.CONTRACTOR) {
        this.formValues["role"] = userDetails?.detail?.role || "";
        this.formValues["standing"] = userDetails?.detail?.standing || "";
        this.formValues["abn_or_tax_reference"] =
          userDetails?.detail?.abn_or_tax_reference || "";
        this.formValues["education_level"] =
          userDetails?.detail?.education_level || "";
        this.formValues["education_from"] =
          userDetails?.detail?.education_from || "";
        this.formValues["first_editing"] =
          userDetails?.detail?.first_editing || "";
        this.formValues["field_of_expertise"] =
          userDetails?.detail?.field_of_expertise || "";
      } else {
        this.formValues["institution"] = userDetails?.institution || "";
      }

      this.emailFormValues.email = userDetails?.email || "";
      this.date = userDetails?.date_of_birth
        ? moment(userDetails?.date_of_birth, "DD-MM-YYYY").toString()
        : "";
    },
  },
  created() {
    if (!this.isCreateMode) {
      this.setInitialFormValue(this.userDetail);
    }
  },
};
</script>
