<template>
  <div class="unassigned-jobs-table">
    <div class="unassigned-jobs-table__header row-start f-12-white f-bold">
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.date_added") }}</div>
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.contractor_time_left") }}
      </div>
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.time_finalize") }}</div>
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.payout") }}</div>
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.word_count") }}</div>
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.assigned_to") }}
      </div>
    </div>
    <div
      class="unassigned-jobs-table__body row-start f-12-black pointer"
      v-for="job in jobsList"
      :key="job.id"
      @click="toJob(job.id)"
    >
      <div class="lg-2 p-4 s-align-5 bb-black bl-black br-black">
        {{ dateFormat(job.created_at) }}
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        <template v-if="job?.paid_at">{{ contractorTimeLeft(job) }}</template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        <template v-if="job?.paid_at">{{ timeToFinalize(job) }}</template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        <template v-if="job.payout">
          {{ $n(job.payout, "currency", job.currency === "aud" ? "en" : "ja") }}
        </template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{ job.word_count }}
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{ fullName(job?.contractor) }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  dateFormat,
  fullName,
  contractorTimeLeft,
  timeToFinalize,
} from "@/helpers/commonHelpers";

export default {
  name: "AssignedJobsTable",
  props: {
    jobsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      componentInterval: null,
    };
  },
  methods: {
    // Methods imported from commonHelpers.js
    dateFormat,
    fullName,
    contractorTimeLeft,
    timeToFinalize,
    toJob(jobId) {
      this.$router.push({
        name: "JobDetails",
        params: {
          jobId: jobId,
        },
      });
    },
  },
  mounted() {
    // This interval for rerender component once per minute
    // to start the timers
    this.componentInterval = setInterval(() => {
      this.$forceUpdate();
    }, 60000);
  },
  beforeUnmount() {
    clearInterval(this.componentInterval);
  },
};
</script>
