export const GET_ORDER_HADBOOKS = "GET_ORDER_HADBOOKS";
export const SET_ORDER_HADBOOKS = "SET_ORDER_HADBOOKS";
export const CREATE_ORDER = "CREATE_ORDER";
export const PAY_ORDER = "PAY_ORDER";
export const GET_CUSTOMER_ORDERS = "GET_CUSTOMER_ORDERS";
export const APPROVE_ORDER = "APPROVE_ORDER";
export const REJECT_ORDER = "REJECT_ORDER";
export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE";
export const SET_ORDER_DATA = "SET_ORDER_DATA";
export const CANCEL_ORDER = "CANCEL_ORDER";
