<template>
  <div class="user-profile-submenu row-between f-14-white pv-3 ph-7">
    <div class="lg-10">
      <ul class="row-start">
        <li
          class="mr-30"
          v-for="(link, index) in availableMenu(menu)"
          :key="index"
        >
          <router-link :to="link.link">
            {{ $t(link.title) }}
          </router-link>
        </li>
      </ul>
    </div>
    <!-- <div class="lg-2 s-align-6">{{ $t("customer_support") }}</div> -->
  </div>
</template>
<script>
import { checkPermission } from "@/components/accessControl/checkPermission";
import { ROLES } from "@/constants/commonConstants";
import { mapGetters } from "vuex";

export default {
  name: "AppUserSubmenu",
  data() {
    return {
      menu: [
        {
          link: { name: "OrderPage" },
          title: "btn.order_now",
          roles: [ROLES.CLIENT],
        },
        {
          link: { name: "MyOrders" },
          title: "my_orders",
          roles: [ROLES.CLIENT],
        },
        {
          link: { name: "UnassignedJobs" },
          title: "unassigned_jobs",
          roles: [ROLES.CONTRACTOR, ROLES.ADMIN],
        },
        {
          link: { name: "AllJobs" },
          title: "all_jobs",
          roles: [ROLES.ADMIN],
        },
        {
          link: { name: "MyJobs" },
          title: "my_jobs",
          roles: [ROLES.CONTRACTOR, ROLES.ADMIN],
        },
        {
          link: { name: "MyDetails" },
          title: "my_details",
          roles: [ROLES.CONTRACTOR, ROLES.CLIENT, ROLES.ADMIN],
        },
        {
          link: {
            name: "UsersManagment",
            params: { userRole: ROLES.CONTRACTOR },
          },
          title: "contractor_accounts",
          roles: [ROLES.ADMIN],
        },
        {
          link: { name: "UsersManagment", params: { userRole: ROLES.CLIENT } },
          title: "customer_accounts",
          roles: [ROLES.ADMIN],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userRole: "user/getUserRole",
    }),
  },
  methods: {
    availableMenu(menu) {
      let menuForRender = [];

      menu.forEach((item) => {
        if (!item.roles.length || checkPermission(item.roles)) {
          menuForRender.push(item);
        }
      });
      return menuForRender;
    },
  },
};
</script>
