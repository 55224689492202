<template>
  <svg
    :height="size"
    :width="size"
    class="edit-icon"
    viewBox="-318.52 225.22 97.21 95.88"
  >
    <path
      class="st0"
      d="M-226.23,252.01c1.45-1.46,2.26-3.39,2.26-5.45s-0.8-3.99-2.26-5.45l-11.82-11.82c-2.91-2.91-7.99-2.91-10.9,0l-9.85,9.85c0,0,0,0,0,0c0,0,0,0,0,0l-50.95,50.59l-6.98,24.42c-0.43,1.49-0.01,3.1,1.09,4.19c0.81,0.81,1.9,1.25,3.02,1.25c0.39,0,0.78-0.05,1.17-0.16l24.43-6.98l0.39-0.39L-226.23,252.01z M-257.3,243.67l6.83,6.83l-47.54,47.18l-6.83-6.83L-257.3,243.67z M-240.61,260.36l-47.54,47.18l-6.83-6.83l47.54-47.18L-240.61,260.36z M-312.63,315.34l5.83-20.4l7.28,7.28c0,0,0,0,0,0s0,0,0,0l7.29,7.29L-312.63,315.34z M-228.24,246.56c0,0.92-0.36,1.78-1.01,2.43l-8.34,8.34l-8.34-8.34c0,0,0,0,0,0s0,0,0,0l-8.34-8.34l8.34-8.34c1.3-1.3,3.56-1.3,4.86,0l11.82,11.82C-228.6,244.78-228.24,245.65-228.24,246.56z"
    />
  </svg>
</template>

<script>
export default {
  name: "EditIcon",
  props: {
    size: {
      type: [Number, String],
      default: 12,
    },
  },
};
</script>
