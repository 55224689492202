import { ifAuthenticated } from "@/helpers/routerHelpers";
import { ROLES } from "@/constants/commonConstants";

const customerRoutes = [
  {
    path: "/my-orders",
    name: "MyOrders",
    component: () =>
      import(
        /* webpackChunkName: "MyOrders" */ "@/views/customer/MyOrdersPage.vue"
      ),
    meta: {
      layout: "main",
      permission: {
        roles: [ROLES.CLIENT],
        noAccessRedirect: "/security/role/forbidden",
      },
    },
    beforeEnter: ifAuthenticated,
  },
  // Redirect from Stripe checkout
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccess" */ "@/views/customer/PaymentSuccess.vue"
      ),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/payment-failed",
    name: "PaymentFailed",
    component: () =>
      import(
        /* webpackChunkName: "PaymentFailed" */ "@/views/customer/PaymentFailed.vue"
      ),
    meta: {
      layout: "main",
    },
  },
];

export default customerRoutes;
