<template>
  <svg
    viewBox="0 0 13 9"
    fill="none"
    :width="size"
    :height="size"
    :style="{ transform: 'rotate(' + transform + 'deg)' }"
    class="arrow-icon"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8333 0.261719L12.8351
    1.35698L6.66753 8.10035L0.5 1.35698L1.50173 0.261719L6.66753 5.90983L11.8333
    0.261719Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowIcon",
  props: {
    size: {
      type: [Number, String],
      default: 13,
    },
    transform: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>

<style scoped>
.arrow-icon {
  display: inline-block;
  vertical-align: baseline;
}
</style>
