<template>
  <div class="app-header">
    <div class="app-header__container row-baseline">
      <router-link to="/" class="app-header__logo-container lg-4">
        <img src="@/assets/images/logo.png" alt="Eneo Logo" />
        <span class="app-header__site-name">{{ $t("site_name") }}</span>
      </router-link>
      <div class="row-end lg-8">
        <nav class="app-header__nav">
          <ul class="row-baseline">
            <li
              v-for="(item, index) in availableMenu"
              :key="index"
              class="row-static f-18-white s-align-5"
            >
              <template v-if="item.hiperLink">
                <component :is="item.icon" class="mr-4 i-white s-align-5" />
                <a :href="item.link" target="_blank">{{ $t(item.title) }}</a>
              </template>
              <template v-else-if="item.button">
                <component :is="item.icon" class="mr-4 i-white s-align-5" />
                <button class="transparent-button f-18-white" @click="logout">
                  {{ $t(item.title) }}
                </button>
              </template>
              <template v-else>
                <router-link
                  class="row-static f-medium"
                  :to="{ name: item.link }"
                >
                  <component :is="item.icon" class="mr-4 i-white s-align-5" />
                  {{ $t(item.title) }}
                </router-link>
              </template>
            </li>
          </ul>
        </nav>
        <vue-select
          v-show="isClientRole"
          class="vue-select--locale ml-30 pointer"
          :options="locales"
          label-by="title"
          value-by="locale"
          :empty-model-value="locales[1]"
          close-on-select
          v-model="$i18n.locale"
          @selected="saveLocale($event)"
        >
          <template #label="{ selected }">
            <template v-if="selected">
              <div class="row-baseline">
                <component :is="selected.icon" class="mr-2 s-align-5" />
                <div>{{ selected.title }}</div>
              </div>
            </template>
          </template>
          <template #dropdown-item="{ option }">
            <div class="row-baseline">
              <component :is="option.icon" class="mr-2 s-align-5" />
              <div>{{ option.title }}</div>
            </div>
          </template>
        </vue-select>
      </div>
    </div>
  </div>
  <load-spinner v-if="loading" size="full-white" />
</template>
<script>
import {
  HomeIcon,
  HelpIcon,
  LockIcon,
  UserIcon,
  UsaFlagIcon,
  JapanFlagIcon,
} from "@/icons";
import { mapGetters } from "vuex";
import { getTimezoneName } from "@/helpers/commonHelpers.js";
import {
  EN_DOMAIN,
  JA_DOMAIN,
  ROLES,
  EN_PROMO_DOMAIN,
  JA_PROMO_DOMAIN,
} from "@/constants/commonConstants";

export default {
  name: "AppHeader",
  components: {
    HomeIcon,
    HelpIcon,
    LockIcon,
    UserIcon,
    UsaFlagIcon,
    JapanFlagIcon,
  },
  data: () => {
    return {
      locales: [
        {
          locale: "ja",
          title: "日本語",
          icon: "japan-flag-icon",
        },
        {
          locale: "en",
          title: "English",
          icon: "usa-flag-icon",
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuth: "auth/isAuth",
      userId: "user/getUserId",
      userRole: "user/getUserRole",
      userLocale: "user/getUserLocale",
    }),
    menu() {
      return [
        {
          title: "Homepage",
          link: `${this.mainDomain}`,
          icon: "home-icon",
          forAuthUser: -1,
          hiperLink: true,
        },
        {
          title: "Help",
          link: `${this.mainDomain}/faq-help/`,
          icon: "help-icon",
          forAuthUser: -1,
          hiperLink: true,
        },
        {
          title: "Profile",
          link: "MyDetails",
          icon: "user-icon",
          forAuthUser: true,
        },
        {
          title: "Login",
          link: "AuthPage",
          icon: "lock-icon",
          forAuthUser: false,
        },
        {
          title: "Logout",
          icon: "lock-icon",
          forAuthUser: true,
          button: true,
        },
      ];
    },
    availableMenu() {
      return this.menu.filter(
        (m) => m.forAuthUser === this.isAuth || m.forAuthUser === -1
      );
    },
    isClientRole() {
      return this.userRole === ROLES.CLIENT || this.userRole === undefined;
    },
    isJapanLocale() {
      return this.$i18n.locale === "ja";
    },
    mainDomain() {
      return this.isJapanLocale ? JA_PROMO_DOMAIN : EN_PROMO_DOMAIN;
    },
  },
  watch: {
    userLocale(newVal) {
      if (newVal) {
        this.$i18n.locale = newVal;
      }
    },
  },
  methods: {
    logout() {
      this.$store.commit("auth/AUTH_LOGOUT");
      this.$store.commit("user/SET_USER_INFO", {});
    },
    async saveLocale(e) {
      let requestIsDone = false;
      this.loading = true;
      if (!this.isAuth) {
        window.location.href =
          e.locale === "ja"
            ? `${JA_DOMAIN}${location.pathname}`
            : `${EN_DOMAIN}${location.pathname}`;
        return;
      }

      let formData = {
        userId: this.userId || localStorage.getItem("user-id"),
        payload: {
          lang: e.locale,
          timezone: getTimezoneName(),
        },
        whomEditing: this.userRole,
      };

      await this.$store
        .dispatch("user/UPDATE_USER_INFO", formData)
        .then((response) => {
          requestIsDone = response === true ? true : false;
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
          this.loading = false;
        });

      if (requestIsDone) {
        setTimeout(() => {
          window.location.href =
            e.locale === "ja"
              ? `${JA_DOMAIN}${location.pathname}`
              : `${EN_DOMAIN}${location.pathname}`;
        }, 3000);
      }
    },
  },
};
</script>
