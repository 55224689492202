export const AUTH_SIGNUP = "AUTH_SIGNUP";
export const REGISTER_CONTRACTOR = "REGISTER_CONTRACTOR";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESEND_VERIFICATION_EMAIL = "RESEND_VERIFICATION_EMAIL";
export const CHECK_EMAIL_EXISTS = "CHECK_EMAIL_EXISTS";
