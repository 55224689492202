/* eslint-disable no-empty-pattern */
import {
  GET_ALL_ASSIGNED_JOBS,
  GET_ALL_REJECTED_JOBS,
  GET_ALL_FINISHED_JOBS,
  GET_ALL_APPROVED_JOBS,
  GET_JOB_BY_ID,
  EDIT_JOB,
  GET_CONTRACTORS_LIST,
  SET_CONTRACTORS,
  UPLOAD_FILE,
  DELETE_FILE,
  ADD_NOTE_TO_JOB,
  GET_ORDER_FILES,
  CHANGE_CONTRACTOR_EMAIL,
} from "../actions/admin";
import api from "@/api/api.js";

export default {
  namespaced: true,
  state: {
    contractorsList: [],
  },
  getters: {
    getContractors: (state) => state.contractorsList,
  },
  mutations: {
    [SET_CONTRACTORS](state, payload) {
      state.contractorsList = payload?.contractors || [];
    },
  },
  actions: {
    [GET_ALL_ASSIGNED_JOBS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/assigned-jobs`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_ALL_REJECTED_JOBS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/rejected-jobs`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_ALL_FINISHED_JOBS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/finished-jobs`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_ALL_APPROVED_JOBS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/approved-jobs`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_JOB_BY_ID]({}, jobId) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/${jobId}`)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [EDIT_JOB]({}, { jobId, payload }) {
      return new Promise((resolve, reject) => {
        api
          .put(`/orders/${jobId}`, payload)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_CONTRACTORS_LIST]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .get(`/handbook`, payload)
          .then((response) => response.data.data)
          .then((data) => {
            commit("SET_CONTRACTORS", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPLOAD_FILE]({}, payload) {
      return new Promise((resolve, reject) => {
        api
          .post(`/files`, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DELETE_FILE]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/files`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ADD_NOTE_TO_JOB]({}, { jobId, payload }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/orders/${jobId}/leave-note`, payload)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_ORDER_FILES]({}, { jobId, params }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/${jobId}/files`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CHANGE_CONTRACTOR_EMAIL]({}, { userId, payload }) {
      return new Promise((resolve, reject) => {
        api
          .put(`/change-contractor-email/${userId}`, payload)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
