<template>
  <div class="unassigned-jobs-table">
    <div class="unassigned-jobs-table__header row-start f-12-white f-bold">
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.date_accepted") }}</div>
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.date_completed") }}
      </div>
      <div class="lg-4 p-4 s-align-5">{{ $t("jobs.table.status") }}</div>
      <div :class="['p-4 s-align-5', showApprovedTimer ? 'lg-1' : 'lg-2']">
        {{ $t("jobs.table.payout") }}
      </div>
      <div :class="['p-4 s-align-5', showApprovedTimer ? 'lg-1' : 'lg-2']">
        {{ $t("jobs.table.word_count") }}
      </div>
      <div v-if="showApprovedTimer" class="lg-2 p-4 s-align-5">
        {{ $t("order.table.approval_timer") }}
      </div>
    </div>
    <div
      :class="[
        'unassigned-jobs-table__body row-start f-12-black',
        notClickedRow ? '' : 'pointer',
      ]"
      v-for="job in jobsList"
      :key="job.id"
      @click="toJob(job.id)"
    >
      <div class="lg-2 p-4 s-align-5 bb-black bl-black br-black">
        {{ dateFormat(job.accepted_at, $t("jobs.table.not_accepted")) }}
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{ dateFormat(job.finished_at) }}
      </div>
      <div class="lg-4 p-4 s-align-5 bb-black br-black">
        {{ $t(showCorrectStatus(job)) }}
      </div>
      <div
        :class="[
          'p-4 s-align-5 bb-black br-black t-center',
          showApprovedTimer ? 'lg-1' : 'lg-2',
        ]"
      >
        <template v-if="job.payout">
          {{ $n(job.payout, "currency", job.currency === "aud" ? "en" : "ja") }}
        </template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div
        :class="[
          'p-4 s-align-5 bb-black br-black',
          showApprovedTimer ? 'lg-1' : 'lg-2',
        ]"
      >
        {{ job.word_count }}
      </div>
      <div v-if="showApprovedTimer" class="lg-2 s-align-5 bb-black br-black">
        <template
          v-if="job.finished_at && job.status === ORDER_STATUSES[6].STATUS"
        >
          {{ timeToApprove(job.finished_at) }}
        </template>
        <template
          v-else-if="job.finished_at && job.status !== ORDER_STATUSES[6].STATUS"
        >
          0 day(s) 0 hour(s) 0 minute(s)
        </template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  dateFormat,
  showCorrectStatus,
  timeToApprove,
} from "@/helpers/commonHelpers";
import { ROLES, ORDER_STATUSES } from "@/constants/commonConstants";
import { mapGetters } from "vuex";

export default {
  name: "FinishedJobsTable",
  components: {},
  props: {
    jobsList: {
      type: Array,
      required: true,
    },
    showApprovedTimer: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {
      ROLES,
      ORDER_STATUSES,
      componentInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      userRole: "user/getUserRole",
    }),
    notClickedRow() {
      return this.userRole !== ROLES.ADMIN;
    },
  },
  methods: {
    // Methods imported from commonHelpers.js
    dateFormat,
    showCorrectStatus,
    timeToApprove,
    toJob(jobId) {
      if (this.notClickedRow) {
        return;
      }

      this.$router.push({
        name: "JobDetails",
        params: {
          jobId: jobId,
        },
      });
    },
  },
  mounted() {
    // This interval for rerender component once per minute
    // to start the timers
    this.componentInterval = setInterval(() => {
      this.$forceUpdate();
    }, 60000);
  },
  beforeUnmount() {
    clearInterval(this.componentInterval);
  },
};
</script>
