import { setLocale } from "yup";

export default setLocale({
  mixed: {
    required: ({ value }) => ({ key: "required_error", values: { value } }),
    oneOf: ({ value }) => ({
      key: "confirm_password_error",
      values: { value },
    }),
  },
  string: {
    email: ({ value }) => ({ key: "email_error", values: { value } }),
    required: ({ value }) => ({ key: "required_error", values: { value } }),
    min: ({ min }) => ({ key: "field_too_short", values: { min } }),
    matches: ({ value }) => ({ key: "phone_not_valid", values: { value } }),
  },
  number: {
    integer: ({ value }) => ({ key: "integer_error", values: { value } }),
  },
});
