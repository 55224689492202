<template>
  <div class="unassigned-jobs-table">
    <div class="unassigned-jobs-table__header row-start f-12-white f-bold">
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.rejected") }}</div>
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.rejected_by") }}</div>
      <div class="lg-3 p-4 s-align-5">{{ $t("jobs.table.status") }}</div>
      <div class="lg-1 p-4 s-align-5">{{ $t("jobs.table.payout") }}</div>
      <div class="lg-2 p-4 s-align-5">{{ $t("jobs.table.word_count") }}</div>
      <div class="lg-2 p-4 s-align-5">
        {{ $t("jobs.table.assigned_to") }}
      </div>
    </div>
    <div
      class="unassigned-jobs-table__body row-start f-12-black pointer"
      v-for="job in jobsList"
      :key="job.id"
      @click="toJob(job.id)"
    >
      <div class="lg-2 p-4 s-align-5 bb-black bl-black br-black">
        {{ dateFormat(job.rejected_at) }}
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{ rejectedBy(job) }}
      </div>
      <div class="lg-3 p-4 s-align-5 bb-black br-black">
        {{ $t(showCorrectStatus(job)) }}
      </div>
      <div class="lg-1 p-4 s-align-5 bb-black br-black t-center">
        <template v-if="job.payout">
          {{ $n(job.payout, "currency", job.currency === "aud" ? "en" : "ja") }}
        </template>
        <template v-else>{{ $t("no_info") }}</template>
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{ job.word_count }}
      </div>
      <div class="lg-2 p-4 s-align-5 bb-black br-black">
        {{ fullName(job?.contractor) }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  dateFormat,
  fullName,
  showCorrectStatus,
} from "@/helpers/commonHelpers";
import { ORDER_STATUSES } from "@/constants/commonConstants";

export default {
  name: "RejectedJobsTable",
  props: {
    jobsList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    // Methods imported from commonHelpers.js
    dateFormat,
    fullName,
    showCorrectStatus,
    rejectedBy(job) {
      if (job.status === ORDER_STATUSES[3].STATUS) {
        return this.fullName(job.contractor);
      }

      if (job.status === ORDER_STATUSES[7].STATUS) {
        return this.fullName(job.client);
      }
    },
    toJob(jobId) {
      this.$router.push({
        name: "JobDetails",
        params: {
          jobId: jobId,
        },
      });
    },
  },
};
</script>
