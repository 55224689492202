<template>
  <div class="orders-table" v-for="order in orders" :key="order.id">
    <div
      :class="[
        'table-row-header row-start pointer f-12-white f-bold',
        { 'mb-2': order.id !== expandedRowId },
      ]"
      @click="expandRow(order.id)"
    >
      <div class="column-start lg-2">
        <span>{{ $t("order.table.status") }}</span>
        <span>{{ showCorrecrStatus(order) }}</span>
      </div>
      <div class="column-start lg-3">
        <span>{{ $t("order.table.order_reference") }}</span>
        <span>
          <template v-if="order.payment_intent_id">
            {{ showOrderId(order) }}
          </template>
          <template v-else>{{ $t("no_info") }}</template>
        </span>
      </div>
      <div :class="['column-start', isCompletedOrders ? 'lg-2' : 'lg-3']">
        <span>{{ $t("order.table.payment_date") }}</span>
        <span>
          <template v-if="order.paid_at">
            {{ dateFormatWithTZ(order.paid_at) }}
          </template>
          <template v-else>{{ $t("no_info") }}</template>
        </span>
      </div>
      <div :class="['column-start lg-1', isCompletedOrders ? 'pl-2' : '']">
        <span>{{ $t("order.table.total_paid") }}</span>
        <span>{{
          $n(order.total, "currency", order.currency === "aud" ? "en" : "ja")
        }}</span>
      </div>
      <div v-if="!isCompletedOrders" class="column-start lg-2">
        <span>{{ $t("order.table.eta") }}</span>
        <span>
          <template v-if="order.paid_at">
            {{ estimatedTimeArrival(order) }}
          </template>
          <template v-else>{{ $t("no_info") }}</template>
        </span>
      </div>
      <div v-else class="column-start lg-2">
        <span>{{ $t("order.table.completion_date") }}</span>
        <span class="l-spacing-negative-02">
          <template v-if="order.finished_at">
            {{ dateFormatWithTZ(order.finished_at) }}
          </template>
          <template v-else>{{ $t("no_info") }}</template>
        </span>
      </div>
      <div
        v-if="isCompletedOrders && order.status === ORDER_STATUSES[6].STATUS"
        class="column-start lg-2 pl-8"
      >
        <span>{{ $t("order.table.approval_timer") }}</span>
        <span>
          <template v-if="order.finished_at">
            {{ approvalTimer(order) }}
          </template>
          <template v-else>{{ $t("no_info") }}</template>
        </span>
      </div>
      <div class="column-center s-align-5 lg-1 ml-auto">
        <span class="s-align-5">
          <arrow-icon
            :transform="order.id !== expandedRowId ? 0 : -180"
            size="13"
            class="i-white mr-2"
          />
          {{ $t("order.table.details") }}
        </span>
      </div>
    </div>
    <div
      class="table-row-expanded f-12-black mb-2"
      v-show="order.id === expandedRowId"
    >
      <div class="row-between mb-40">
        <div class="lg-3 column-start">
          <span class="mb-20">{{ $t("order.table.name") }}</span>
          <span>
            {{ `${order.client.first_name} ${order.client.last_name}` }}
          </span>
        </div>
        <div class="lg-3 column-start">
          <span class="mb-20">{{ $t("order.table.institution") }}</span>
          <span>
            <template v-if="order?.client?.institution">
              {{ `${order.client.institution}` }}
            </template>
            <template v-else>{{ $t("no_info") }}</template>
          </span>
        </div>
        <div class="lg-3 column-start">
          <span class="mb-20">{{ $t("order.table.word_count") }}</span>
          <span>{{ order.word_count }}</span>
        </div>
        <div class="lg-3 column-start">
          <span class="mb-20">{{ $t("order.table.service") }}</span>
          <span>{{ showSelectedRate(order) }}</span>
        </div>
      </div>
      <div class="row-between">
        <div class="lg-3 column-start">
          <span class="mb-20">{{ $t("order.table.doc") }}</span>
          <span
            v-if="order.document_original"
            class="underline pointer"
            @click="
              downloadFile(
                order.document_original.id,
                order.document_original.original_name
              )
            "
          >
            {{ order.document_original.original_name }}
          </span>
          <span v-else>{{ $t("order.table.doc_empty") }}</span>
        </div>
        <div
          :class="[
            'column-start',
            order.status === ORDER_STATUSES[0].STATUS ? 'lg-2' : 'lg-3',
          ]"
        >
          <span class="mb-20">{{ $t("order.table.edited_doc") }}</span>
          <span
            v-if="order.document_edited"
            class="underline pointer"
            @click="
              downloadFile(
                order.document_edited.id,
                order.document_edited.original_name
              )
            "
          >
            {{ order.document_edited.original_name }}
          </span>
          <span v-else>{{ $t("order.table.doc_empty") }}</span>
        </div>
        <div
          class="lg-2 column-start s-align-5"
          v-if="order.status === ORDER_STATUSES[0].STATUS"
        >
          <button
            class="secondary-button secondary-button--large mt-auto w-60"
            :disabled="orderInProcessId === order.id && payLoading"
            @click="payOrder(order)"
          >
            <template v-if="orderInProcessId === order.id && payLoading">
              <load-spinner size="mini" loaderColor="white" class="s-align-5" />
            </template>
            <template v-else>{{ $t("btn.pay_order") }}</template>
          </button>
        </div>
        <div
          :class="[
            'column-start',
            order.status === ORDER_STATUSES[0].STATUS ? 'lg-1' : 'lg-2',
          ]"
        >
          <button
            :class="[
              'secondary-button secondary-button--large  mt-auto',
              { 'w-100': order.status === ORDER_STATUSES[0].STATUS },
            ]"
            :disabled="orderInProcessId === order.id && cancelOrderLoading"
            @click="cancelOrder(order)"
          >
            <template
              v-if="orderInProcessId === order.id && cancelOrderLoading"
            >
              <load-spinner size="mini" loaderColor="white" class="s-align-5" />
            </template>
            <template v-else>{{ $t("btn.cancel_order") }}</template>
          </button>
        </div>
        <div
          :class="[
            'column-start lg-2',
            { 's-align-5': order.status === ORDER_STATUSES[0].STATUS },
          ]"
        >
          <button
            class="secondary-button secondary-button--large mt-auto"
            @click="downloadInvoice(order)"
          >
            {{ $t("order.table.invoice") }}
          </button>
        </div>
        <div class="lg-2 column-start pointer">
          <a
            class="secondary-button secondary-button--large mt-auto"
            :href="`mailto:${CLIENT_SUPPORT_EMAIL}`"
            target="_blank"
          >
            {{ $t("order.table.support") }}
          </a>
        </div>
      </div>
      <VeeForm
        v-if="showApproveRejectButtons(order)"
        class="w-100"
        as="div"
        :validation-schema="validationSchema"
        :initial-values="{ reason: order?.rejection_reason || '' }"
        @invalid-submit="onInvalidSubmit"
        v-slot="{ errors, handleSubmit }"
      >
        <form
          @submit="handleSubmit($event, rejectOrder)"
          class="row-between pt-4"
        >
          <div class="lg-3 s-align-5">
            <button
              class="mr-25 secondary-button secondary-button--large"
              @click="approveOrder(order)"
              type="button"
            >
              {{ $t("btn.accept_work") }}
            </button>
            <button
              class="secondary-button secondary-button--large"
              @click="rejectedOdderId = order.id"
              :disabled="order.status === ORDER_STATUSES[7].STATUS"
            >
              {{ $t("btn.reject_work") }}
            </button>
          </div>
          <text-input
            name="reason"
            id="reason"
            :errors="errors"
            :inputContainerClass="'lg-9'"
            fieldType="text"
            :placeholder="$t('order.reson_placeholder')"
            :renderAs="'textarea'"
            :disabled="order.status === ORDER_STATUSES[7].STATUS"
          />
        </form>
      </VeeForm>
    </div>
  </div>
</template>
<script>
import { Form as VeeForm } from "vee-validate";
import * as yup from "yup";
// eslint-disable-next-line no-unused-vars
import setLocale from "@/helpers/validatorLocalization";
import {
  ORDER_RATES,
  ORDER_STATUSES,
  CLIENT_SUPPORT_EMAIL,
} from "@/constants/commonConstants";
import {
  onInvalidSubmit,
  downloadFile,
  dateFormatWithTZ,
  estimatedTimeArrival,
  timeToApprove,
  showOrderId,
} from "@/helpers/commonHelpers";
import { ArrowIcon } from "@/icons";

export default {
  name: "OrdersTable",
  components: {
    VeeForm,
    ArrowIcon,
  },
  data() {
    // Form validation schema
    const validationSchema = yup.object().shape({
      reason: yup.string().required(),
    });

    return {
      ORDER_STATUSES,
      CLIENT_SUPPORT_EMAIL,
      expandedRowId: null,
      validationSchema,
      rejectedOdderId: null,
      componentInterval: null,
      payLoading: false,
      orderInProcessId: null,
      cancelOrderLoading: false,
    };
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    isCompletedOrders: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateOrder"],
  computed: {},
  methods: {
    // Methods imported from commonHelpers.js
    downloadFile,
    onInvalidSubmit,
    dateFormatWithTZ,
    estimatedTimeArrival,
    timeToApprove,
    showOrderId,
    expandRow(id) {
      if (this.expandedRowId === id) {
        this.expandedRowId = null;
      } else {
        this.expandedRowId = id;
      }
    },
    showCorrecrStatus({ status }) {
      let statusName = "";
      ORDER_STATUSES.forEach((s) => {
        if (s.STATUS === status) {
          statusName = s.TRANSLATE_KEY;
        }
      });
      return this.$t(`order_status.${statusName}`);
    },
    showSelectedRate(order) {
      let rateName = "";
      ORDER_RATES.forEach((r) => {
        if (r.ID === order.rate.id) {
          rateName = r.TRANSLATION_KEY;
        }
      });
      return this.$t(`turnaround_time.${rateName}`);
    },
    showApproveRejectButtons({ status }) {
      // Show buttons only for pending_approval status
      return (
        status === ORDER_STATUSES[6].STATUS ||
        status === ORDER_STATUSES[7].STATUS
      );
    },
    approveOrder({ id }) {
      this.$store
        .dispatch("customer/APPROVE_ORDER", id)
        .then((data) => {
          if (data) {
            this.$emit("updateOrder");
          }
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        });
    },
    rejectOrder(values) {
      let payload = {
        orderId: this.rejectedOdderId,
        params: values,
      };

      this.$store
        .dispatch("customer/REJECT_ORDER", payload)
        .then((data) => {
          if (data) {
            this.$emit("updateOrder");
          }
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        })
        .finally(() => (this.rejectedOdderId = null));
    },
    approvalTimer({ finished_at }) {
      if (!this.isCompletedOrders) {
        return;
      }

      return this.timeToApprove(finished_at);
    },
    payOrder({ id }) {
      this.payLoading = true;
      this.orderInProcessId = id;
      let formData = {
        id,
        payload: {
          success_url: `${window.location.origin}/payment-success?id={CHECKOUT_SESSION_ID}`,
          cancel_url: `${window.location.origin}/payment-failed`,
        },
      };

      this.$store
        .dispatch("customer/PAY_ORDER", formData)
        .then(({ checkout_url }) => {
          window.location.href = checkout_url;
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        })
        .finally(() => {
          this.payLoading = false;
          this.orderInProcessId = null;
        });
    },
    cancelOrder({ id, status }) {
      if (status !== ORDER_STATUSES[0].STATUS) {
        this.$store.dispatch(
          "notifications/SHOW_ERROR_NOTIFICATION",
          this.$t("notifications.order_canceled_disabled")
        );
        return;
      }

      this.cancelOrderLoading = true;
      this.orderInProcessId = id;

      this.$store
        .dispatch("customer/CANCEL_ORDER", id)
        .then(() => {
          this.$store.dispatch(
            "notifications/SHOW_SUCCESS_NOTIFICATION",
            this.$t("notifications.order_canceled")
          );
          this.$emit("updateOrder");
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        })
        .finally(() => {
          this.cancelOrderLoading = false;
          this.orderInProcessId = null;
        });
    },
    downloadInvoice({ id: jobId, payment_intent_id }) {
      let orderReference = payment_intent_id
        ? payment_intent_id.slice(
            payment_intent_id.indexOf("_") + 1,
            payment_intent_id.length
          )
        : "";

      this.$store
        .dispatch("customer/DOWNLOAD_INVOICE", jobId)
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Eneo Invoice ${orderReference}.pdf`;
          link.click();
          URL.revokeObjectURL(link);
          link.remove();
        })
        .catch((error) => {
          this.$store.dispatch(
            "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
            error
          );
        });
    },
  },
  mounted() {
    if (this.isCompletedOrders) {
      // This interval for rerender component once per minute
      // to start the timers
      this.componentInterval = setInterval(() => {
        this.$forceUpdate();
      }, 60000);
    }
  },
  beforeUnmount() {
    if (this.isCompletedOrders) {
      clearInterval(this.componentInterval);
    }
  },
};
</script>
