import store from "@/store/index.js";
import api from "@/api/api";
import moment from "moment";
import { ORDER_STATUSES } from "@/constants/commonConstants";

export const onInvalidSubmit = ({ errors }) => {
  /* The method can accept such parameters: values, errors, results
   * values -> current form values
   * errors -> a map of field names and their first error message
   * results ->  a detailed map of field names and their validation results
   */
  store.dispatch("notifications/SHOW_VALIDATION_ERROR_NOTIFICATION", errors);
};

export const downloadFile = (file, fileName) => {
  let params = {
    name: file,
  };

  api
    .post("/files/download", params, { responseType: "blob" })
    .then((response) => {
      let blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link);
      link.remove();
    })
    .catch((error) => {
      store.dispatch("notifications/SHOW_REQUEST_ERROR_NOTIFICATION", error);
    });
};

export const dateFormat = (date, dateErrorMsg) => {
  if (!date) {
    return dateErrorMsg ? dateErrorMsg : "—";
  }

  return moment(date).format("YYYY-MM-DD h:mm A");
};

export const dateFormatWithTZ = (date) => {
  return moment(date).format("YYYY-MM-DD h:mm A ([GMT]Z)");
};

export const fullName = (value) => {
  if (!value?.first_name && !value?.last_name) {
    return "No information";
  }

  const firstName = value.first_name ? value.first_name : "";
  const lastName = value.last_name ? value.last_name : "";
  return `${firstName} ${lastName}`;
};

export const showCorrectStatus = ({ status }) => {
  let statusName = "";
  ORDER_STATUSES.forEach((s) => {
    if (s.STATUS === status) {
      statusName = s.TRANSLATE_KEY;
    }
  });
  return `order_status.${statusName}`;
};

export const estimatedTimeArrival = ({ started_at, duration }) => {
  const deadlineDate = moment(started_at).add("h", duration); // deadline date
  return dateFormatWithTZ(deadlineDate);
};

export const timeToFinalize = ({ started_at, duration, status }) => {
  // Time until the end of the selected service
  const stoppedTimer = "0 day(s) 0 hour(s) 0 minute(s)";

  if (
    status === ORDER_STATUSES[1].STATUS ||
    status === ORDER_STATUSES[2].STATUS ||
    status === ORDER_STATUSES[3].STATUS
  ) {
    const now = moment(new Date()); // todays date
    const end = moment(started_at).add(duration, "h"); // deadline date
    const result = moment.duration(end.diff(now));

    if (moment(now).isAfter(end)) {
      return stoppedTimer;
    } else {
      return `${result.days()}day(s) ${result.hours()}hour(s) ${result.minutes()}minute(s)`;
    }
  } else {
    return stoppedTimer;
  }
};

export const contractorTimeLeft = ({ started_at, duration: dur, status }) => {
  // Time to complete the order by the contractor
  const stoppedTimer = "0 day(s) 0 hour(s) 0 minute(s)";

  if (
    status === ORDER_STATUSES[1].STATUS ||
    status === ORDER_STATUSES[2].STATUS ||
    status === ORDER_STATUSES[3].STATUS
  ) {
    const now = moment(new Date()); // todays date
    const end = moment(started_at).add(dur / 2, "h"); // deadline date
    const result = moment.duration(end.diff(now));

    if (moment(now).isAfter(end)) {
      return stoppedTimer;
    } else {
      return `${result.days()}day(s) ${result.hours()}hour(s) ${result.minutes()}minute(s)`;
    }
  } else {
    return stoppedTimer;
  }
};

export const timeToAccept = ({ started_at, duration: dur }) => {
  // Time for accept order by contractor
  const now = moment(new Date()); // todays date
  const end = moment(started_at).add(dur / 4, "h"); // deadline date
  const result = moment.duration(end.diff(now));

  if (moment(now).isAfter(end)) {
    return "0 day(s) 0 hour(s) 0 minute(s)";
  } else {
    return `${result.days()}day(s) ${result.hours()}hour(s) ${result.minutes()}minute(s)`;
  }
};

export const timeToApprove = (finished_at) => {
  // Time for approve order by client
  const now = moment(new Date()); // todays date
  const end = moment(finished_at).add(30, "days"); // deadline date
  const result = moment.duration(end.diff(now));
  let month = `${result.months()}month`;

  if (moment(now).isAfter(end)) {
    return "0 day(s) 0 hour(s) 0 minute(s)";
  } else {
    return `${result.months() > 0 ? month : ""} 
    ${result.days()}day(s) ${result.hours()}hour(s)`;
  }
};

export const showOrderId = ({ payment_intent_id }) => {
  let result = "";
  result = payment_intent_id.slice(
    payment_intent_id.indexOf("_") + 1,
    payment_intent_id.length
  );
  return result;
};

export const getTimezoneName = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
