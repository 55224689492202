import { app } from "@/main";
import { createI18n } from "vue-i18n";
import { EN_DOMAIN } from "@/constants/commonConstants";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context("../i18n", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "AUD",
      notation: "standard",
      currencyDisplay: "code",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  ja: {
    currency: {
      style: "currency",
      currency: "JPY",
      useGrouping: true,
      currencyDisplay: "name",
      abbreviate: true,
      maximumSignificantDigits: 4,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

function setUserLocale() {
  return window.location.origin === EN_DOMAIN ? "en" : "ja";
}

const i18n = createI18n({
  legacy: false,
  locale: setUserLocale() || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  globalInjection: true,
  messages: loadLocaleMessages(),
  numberFormats,
});

app.use(i18n);
