/* eslint-disable no-empty-pattern */
import {
  GET_UNASSIGNED_JOBS,
  ACCEPT_UNASSIGNED_JOB,
  GET_CONTRACTOR_CURRENT_JOBS,
  UPLOAD_EDITED_DOCUMENT,
  REPORT_UNEDITABLE_DOCUMENT,
  GET_FINISHED_JOBS,
} from "../actions/contractor";
import api from "@/api/api.js";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [GET_UNASSIGNED_JOBS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/unassigned-jobs`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ACCEPT_UNASSIGNED_JOB]({}, jobId) {
      return new Promise((resolve, reject) => {
        api
          .post(`/orders/${jobId}/accept`)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_CONTRACTOR_CURRENT_JOBS]({}) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/contractor-current`)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [GET_FINISHED_JOBS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/orders/contractor-finished`, { params })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPLOAD_EDITED_DOCUMENT]({}, { payload, jobId }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/orders/${jobId}/upload`, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [REPORT_UNEDITABLE_DOCUMENT]({}, { payload, jobId }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/orders/${jobId}/report-uneditable`, payload)
          .then((response) => response.data.data)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
