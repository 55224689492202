import { ifNotAuthenticated } from "@/helpers/routerHelpers";

const authRoutes = [
  {
    path: "/auth",
    name: "AuthPage",
    component: () =>
      import(/* webpackChunkName: "AuthPage" */ "@/views/auth/AuthPage.vue"),
    meta: {
      layout: "main",
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/auth/signup/success",
    name: "SignUpSuccess",
    component: () =>
      import(
        /* webpackChunkName: "signUpSuccess" */ "@/views/auth/SignUpSuccess.vue"
      ),
    meta: {
      layout: "main",
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/reset-password",
    name: "ResetPasswordPage",
    component: () =>
      import(
        /* webpackChunkName: "ResetPasswordPage" */ "@/views/auth/ResetPasswordPage.vue"
      ),
    meta: {
      layout: "main",
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordPage",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPasswordPage" */ "@/views/auth/ForgotPasswordPage.vue"
      ),
    meta: {
      layout: "main",
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/unverified_email",
    name: "UnverifiedEmail",
    component: () =>
      import(
        /* webpackChunkName: "unverifiedEmail" */ "@/views/auth/UnverifiedEmail.vue"
      ),
    meta: {
      layout: "main",
    },
    beforeEnter: ifNotAuthenticated,
  },
];

export default authRoutes;
