<template>
  <svg viewBox="0 0 20 19" :width="size" :height="size" class="help-icon">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18.25C11.7246 18.25 13.387 17.8667 14.8595 17.149L19.0947 17.4579L18.7418 13.7521C19.562 12.4636 20 11.009 20 9.5C20 4.66751 15.5228 0.75 10 0.75C4.47715 0.75 0 4.66751 0 9.5C0 14.3325 4.47715 18.25 10 18.25ZM14.115 15.5043L14.3916 15.3589L16.9053 15.5421L16.6958 13.3427L16.8621 13.1006C17.603 12.0221 18 10.7876 18 9.5C18 5.63401 14.4183 2.5 10 2.5C5.58172 2.5 2 5.63401 2 9.5C2 13.366 5.58172 16.5 10 16.5C11.4715 16.5 12.8824 16.1527 14.115 15.5043ZM10.0003 13.8736C10.5528 13.8736 11.0007 13.4818 11.0007 12.9986C11.0007 12.5153 10.5528 12.1236 10.0003 12.1236C9.44786 12.1236 9 12.5153 9 12.9986C9 13.4818 9.44786 13.8736 10.0003 13.8736ZM9 11.25H11V10.375C11 10.3771 11.0047 10.3726 11.0154 10.3623C11.0417 10.337 11.1039 10.277 11.2205 10.196C11.3207 10.1265 11.3517 10.1085 11.6048 9.96811C12.4661 9.49037 13 8.65962 13 7.75C13 6.30025 11.6569 5.125 10 5.125C8.34315 5.125 7 6.30025 7 7.75H9C9 7.26675 9.44771 6.875 10 6.875C10.5523 6.875 11 7.26675 11 7.75C11 8.0539 10.8225 8.33001 10.534 8.49009C10.2102 8.6697 10.1564 8.70087 9.97749 8.82507C9.38274 9.23792 9 9.72986 9 10.375V11.25Z"
    />
  </svg>
</template>

<script>
export default {
  name: "HelpIcon",
  props: {
    size: {
      type: [Number, String],
      default: 20,
    },
  },
};
</script>
