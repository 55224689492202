export default {
  "site_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eneo"])},
  "Homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームページ"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプ"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
  "Japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
  "login_head_highlighting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントに"])},
  "login_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "register_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント登録"])},
  "forgot_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールをお忘れの方"])},
  "click_to_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサポートへのお問い合わせはこちら"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れの方"])},
  "click_to_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの再設定はこちら"])},
  "work_with_eneo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "more_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再設定する"])},
  "my_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報"])},
  "login_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様のログイン情報"])},
  "personal_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報"])},
  "my_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文"])},
  "customer_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客サポート"])},
  "no_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報なし"])},
  "account_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのアカウントが作成されました！"])},
  "verify_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールを確認し、確認リンクをクリックしてメールアドレスを確認してください。"])},
  "check_spam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認メールが届かない場合は、迷惑メールフォルダを確認してください。"])},
  "file_uploader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリックでアップロード、ドラッグ＆ドロップ"])},
  "allowed_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["対応するファイル形式 DOC、DOCX、ODF"])},
  "my_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の仕事"])},
  "all_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのジョブ"])},
  "unassigned_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられていないジョブ"])},
  "assigned_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたジョブ"])},
  "rejected_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否されたジョブ"])},
  "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このページを表示するためのアクセス権がありません。"])},
  "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページが見つかりません。"])},
  "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
  "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了した ジョブズ"])},
  "approved_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い待ち"])},
  "contractor_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請負業者のアカウント"])},
  "customer_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客アカウント"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ノート"])},
  "original_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元のファイル"])},
  "edited_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集したファイル"])},
  "add_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモを追加"])},
  "read_accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約を読み、"])},
  "terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意します。"])},
  "verify_email_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール確認リンクの有効期限が切れています。"])},
  "resend_verify_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下のボタンをクリックして、メールを確認するか、確認メールを再送信してください。"])},
  "register_billing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求先住所を追加して登録を完了してください"])},
  "address_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
  "footer": {
    "gua_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保証されたセキュリティ"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせはこちら"])},
    "contact_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月～金 10:00 AM - 8:00 PM AEST"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright© 2021 Eneo Proofreading"])}
  },
  "input_fields": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["称号"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メールアドレス"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氏名"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苗字"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
    "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制度"])},
    "institution_or_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制度"])},
    "institution_or_company_opt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制度"])},
    "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規パスワード"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの確認"])},
    "doc_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文書の種類"])},
    "academic_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学術分野または企業分野"])},
    "word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語数"])},
    "turnaround_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プランを選択してください。"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由"])},
    "abn_or_tax_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABNまたは税務リファレンス"])},
    "bank_acc_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント名"])},
    "bsb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSB"])},
    "bank_acc_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["口座番号"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割"])},
    "standing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの地位"])},
    "education_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["達成された最高レベル"])},
    "education_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から達成"])},
    "first_editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初の編集"])},
    "field_of_expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["専門知識の分野"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモメッセージ"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["規約と条件"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村"])},
    "line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
    "line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域区分"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県"])}
  },
  "btn": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント登録"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセット"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を保存"])},
    "change_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールアドレスを変更する"])},
    "update_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの更新"])},
    "order_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文する"])},
    "accept_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご承認までの残り時間"])},
    "reject_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校正作業が拒否されます"])},
    "accept_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仕事を受け入れる"])},
    "to_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィールはこちら"])},
    "download_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントをダウンロード"])},
    "upload_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成したドキュメントをアップロードする"])},
    "contact_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンタクトマネージャー"])},
    "report_un-editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集不可のレポート"])},
    "add_contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請負業者を追加"])},
    "send_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状を送る"])},
    "download_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダウンロードファイル"])},
    "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルをアップロードする"])},
    "upload_finished_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成したファイルをアップロードする"])},
    "delete_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを削除する"])},
    "add_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモを追加"])},
    "to_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文に移動"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい私は同意する"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードをキャンセル"])},
    "pay_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文を支払う"])},
    "resend_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認メールを再送"])},
    "cancel_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文をキャンセルする"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次頁"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])}
  },
  "validation_errors": {
    "required_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は必須項目です。"])},
    "email_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は有効なEメールでなければなりません。"])},
    "field_too_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は8文字以上でなければならない。"])},
    "phone_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は有効ではありません。"])},
    "confirm_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は一致しなければならない。"])},
    "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は整数でなければならない。"])},
    "file_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文書ファイルのアップロードが必要です。"])},
    "letters_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は文字だけでなければならない。"])},
    "word_count_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語数このフィールドは必須です"])},
    "max_word_count_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントは80,000単語以下である必要があります"])},
    "min_word_count_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低注文数は500単語です"])}
  },
  "notifications": {
    "upd_profile_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィールが正常に更新されました"])},
    "upd_email_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メールが正常に変更されました"])},
    "upd_password_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの変更に成功しました"])},
    "register_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの登録が完了しました \n メールアドレスを確認するため、メールをご確認ください。"])},
    "email_verified_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の電子メールは正常に認証されました。"])},
    "not_allowed_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルの種類は、doc、docx、odfに限ります。"])},
    "contractor_reg_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請負業者のアカウントが登録されました！"])},
    "contractor_upd_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請負業者の詳細が正常に更新されました！"])},
    "customer_upd_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客の詳細が正常に更新されました！"])},
    "uneditable_job_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータスのあるジョブのドキュメントのアップロードとダウンロード \n '進行中の保留中の上級編集者' 禁止されています"])},
    "not_file_manipulate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このファイル操作は使用できません。"])},
    "payment_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い成功。"])},
    "uneditable_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この注文の編集不可能なレポートはすでに送信されています。"])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文受付番号がクリップボードにコピーされました。"])},
    "order_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文はキャンセルされました。"])},
    "order_canceled_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この注文は、現在の状態ではキャンセルできません。カスタマーサポートにご連絡ください。"])},
    "country_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国名は必須項目です。"])},
    "email_is_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このメールアドレスで登録されたアカウントはすでに存在しています。"])}
  },
  "order": {
    "form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確実な"])},
    "form_title_highlighting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校正"])},
    "price_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プランの詳細と価格"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お見積もり金額"])},
    "coupon_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンをお持ちですか？"])},
    "order_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受注状況"])},
    "order_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了した注文"])},
    "orders_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ注文がない場合"])},
    "reson_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否理由を追加"])},
    "note_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモテキストを追加"])},
    "table": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
      "order_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文参照"])},
      "payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払日"])},
      "total_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払総額"])},
      "eta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETA"])},
      "completion_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了日"])},
      "approval_timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご承認までの残り時間"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前:"])},
      "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制度"])},
      "word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語数"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご選択のプラン"])},
      "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードした文書"])},
      "edited_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校正後の文書"])},
      "doc_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだアップロードされていません"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インボイスのダウンロード"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサポートへのお問い合わせ"])}
    }
  },
  "order_status": {
    "pending_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新着支払い待ち"])},
    "pending_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中、編集者の割り当てを待ち"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集中"])},
    "uneditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中、編集長が検品中"])},
    "pending_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル払い戻し中"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルと完了"])},
    "pending_approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の承認待ちで納品"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再校正の依頼を受け、編集長を待ち"])},
    "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文確定"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文確定・完了"])}
  },
  "doc_types": {
    "academic_manuscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学術論文原稿"])},
    "grant_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["助成金申請書"])},
    "admission_essay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入学試験小論文"])},
    "undergraduate_essay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学部生用エッセイ"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネス・企業"])}
  },
  "turnaround_time": {
    "standart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンダード"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレミアム"])},
    "platinum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プラチナ"])}
  },
  "services": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プラン名"])},
    "return_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帰着時間"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金／単語"])},
    "standart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンダード"])},
    "standart_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(最低の緊急性)"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレミアム7"])},
    "premium_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(標準の緊急性)"])},
    "platinum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プラチナ"])},
    "platinum_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(最優先)"])},
    "standart_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7日以内に返送"])},
    "premium_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["72時間以内に返送"])},
    "platinum_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間以内に返送"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この価格は校正サービスのものであり、実質的な編集は含まれませんのでご注意ください。サービスの内容については、サービスページをご覧ください。消費税は含まれていません。利用規約が適用されます。"])},
    "tax_appended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消費税込み"])},
    "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語："])},
    "word_within": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語："])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日以内"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間以内"])},
    "asap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["できるだけ早く"])}
  },
  "table_pagination": {
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前へ"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後"])},
    "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示件数"])}
  },
  "contractor": {
    "account_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント詳細"])},
    "bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行の詳細"])},
    "edu_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教育の歴史"])},
    "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者による確認が必要な詳細を変更する（例：達成された最高の教育レベル）"])},
    "click_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マネージャーに連絡するには、ここをクリックしてください"])},
    "jobs_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジョブリストは空です！"])},
    "active_job_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ進行中の作業はありません"])}
  },
  "jobs": {
    "unassigned_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられていないジョブ"])},
    "job_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジョブオーダーの詳細"])},
    "table": {
      "date_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加された日付"])},
      "finish_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残り時間"])},
      "accept_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け入れる時間残り"])},
      "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い"])},
      "word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語数"])},
      "academic_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学術または企業分野"])},
      "date_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受理日"])},
      "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了日"])},
      "submit_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出する残り時間"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
      "contractor_time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請負業者の残り時間"])},
      "time_finalize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイナライズする残り時間"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["に割り当てられた"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否されました"])},
      "rejected_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["によって拒否されました"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス"])},
      "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログ"])},
      "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客名"])},
      "not_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文は受け付けられませんでした"])}
    }
  },
  "admin": {
    "add_contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい請負業者を追加する"])},
    "edit_contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請負業者-詳細を編集"])},
    "edit_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客-詳細を編集"])},
    "contractor_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請負業者のリストは空です"])},
    "clients_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客リストが空です"])},
    "user_table": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成した"])}
    }
  },
  "stripe": {
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ありがとうございました。"])},
    "succes_paid_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文は正常に支払われました。"])},
    "failed_paid_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いは処理されません。 注文を確認してください。"])},
    "reciept_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書が記載されたメールが届きます。"])}
  },
  "uploading_modal": {
    "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重要。"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業指示の確定。"])},
    "p_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは罰せられるかもしれません。 各ポイントを再確認してください！"])},
    "p_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[はい]をクリックすると、この作業指示が完了し、選択したファイルが次のようになることに同意したことになります。"])},
    "li_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この作業指示書に関連する正しい文書。"])},
    "li_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全に改訂され、いかなる種類のエラーもなく注釈が付けられています。"])},
    "li_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約のすべての作業指示規定に準拠しています。"])},
    "p_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上記のいずれかに違反したためにクライアントがドキュメントの再編集を要求した場合、Eneoのアカウントは予告なしに一時停止または終了される可能性があります。 クライアントが別の下請け業者による再編集を要求した場合、この費用をカバーするために支払いが減額される場合があります。"])},
    "p_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理上の裁量により、あなたの仕事が過失の程度まで容認できないとみなされた場合、あなたはこの仕事の注文に対して支払われないかもしれません。"])}
  },
  "files": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジョブファイル"])},
    "no_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除されません"])},
    "table": {
      "date_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加された日付"])},
      "date_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除された日付"])},
      "filenane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル"])},
      "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログ"])}
    }
  }
}