<template>
  <div :class="['form-input-container', inputContainerClass]">
    <label v-if="label" :for="id">
      {{ label }}
    </label>
    <slot>
      <Field
        :as="renderAs"
        :id="id"
        :name="name"
        :class="[
          `form-${renderAs}-field`,
          errors && errors[name] ? `form-${renderAs}-field-invalid` : '',
        ]"
        :type="fieldType"
        :validateOnInput="true"
        :placeholder="placeholder"
        v-bind="$attrs"
      />
    </slot>
  </div>
</template>

<script>
import { Field } from "vee-validate";

export default {
  name: "TextInput",
  inheritAttrs: false,
  components: {
    Field,
  },
  computed: {},
  props: {
    id: {
      type: [String],
      default: "",
    },
    label: {
      type: [String],
      default: "",
    },
    name: {
      type: [String],
      required: true,
    },
    fieldType: {
      type: [String],
      default: "text",
    },
    placeholder: {
      type: [String],
      default: "",
    },
    errors: {
      type: Object,
      required: false,
    },
    inputContainerClass: {
      type: String,
      default: "",
    },
    renderAs: {
      type: String,
      default: "input",
    },
  },
};
</script>
