<template>
  <svg viewBox="0 0 20 22" :width="width" :height="height" class="user-icon">
    <path
      d="M13.7105 11.71C14.6909 10.9387 15.4065 9.88092 15.7577 8.68394C16.109 7.48697 16.0784 6.21027 15.6703 5.03147C15.2621 3.85267 14.4967 2.83039 13.4806 2.10686C12.4644 1.38332 11.2479 0.994507 10.0005 0.994507C8.75303 0.994507 7.5366 1.38332 6.52041 2.10686C5.50423 2.83039 4.73883 3.85267 4.3307 5.03147C3.92257 6.21027 3.892 7.48697 4.24325 8.68394C4.59449 9.88092 5.31009 10.9387 6.29048 11.71C4.61056 12.383 3.14477 13.4994 2.04938 14.9399C0.953983 16.3805 0.270048 18.0913 0.070485 19.89C0.0560396 20.0213 0.0676015 20.1542 0.10451 20.2811C0.141419 20.4079 0.202952 20.5263 0.285596 20.6293C0.452504 20.8375 0.695269 20.9708 0.960485 21C1.2257 21.0292 1.49164 20.9518 1.69981 20.7849C1.90798 20.618 2.04131 20.3752 2.07049 20.11C2.29007 18.1552 3.22217 16.3498 4.6887 15.0388C6.15524 13.7278 8.05338 13.003 10.0205 13.003C11.9876 13.003 13.8857 13.7278 15.3523 15.0388C16.8188 16.3498 17.7509 18.1552 17.9705 20.11C17.9977 20.3557 18.1149 20.5827 18.2996 20.747C18.4843 20.9114 18.7233 21.0015 18.9705 21H19.0805C19.3426 20.9698 19.5822 20.8373 19.747 20.6313C19.9119 20.4252 19.9886 20.1624 19.9605 19.9C19.76 18.0962 19.0724 16.381 17.9713 14.9382C16.8703 13.4954 15.3974 12.3795 13.7105 11.71ZM10.0005 11C9.20936 11 8.436 10.7654 7.7782 10.3259C7.12041 9.88636 6.60772 9.26164 6.30497 8.53074C6.00222 7.79983 5.923 6.99557 6.07734 6.21964C6.23168 5.44372 6.61265 4.73099 7.17206 4.17158C7.73147 3.61217 8.4442 3.2312 9.22012 3.07686C9.99605 2.92252 10.8003 3.00173 11.5312 3.30448C12.2621 3.60724 12.8868 4.11993 13.3264 4.77772C13.7659 5.43552 14.0005 6.20888 14.0005 7C14.0005 8.06087 13.5791 9.07828 12.8289 9.82843C12.0788 10.5786 11.0614 11 10.0005 11Z"
    />
  </svg>
</template>

<script>
export default {
  name: "UserIcon",
  props: {
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 22,
    },
  },
};
</script>
