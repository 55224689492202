import axios from "axios";
import store from "@/store/index.js";
import router from "@/router/index";
const token = localStorage.getItem("user-token");

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${token || ""}`,
    Accept: "application/json",
  },
  // responseType` indicates the type of data that the server will respond with
  // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
  // browser only: 'blob'
  responseType: "json", // default

  // responseEncoding` indicates encoding to use for decoding responses
  // Note: Ignored for `responseType` of 'stream' or client-side requests
  responseEncoding: "utf8", // default,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.commit("auth/AUTH_LOGOUT");
      store.commit("user/SET_USER_INFO", {});
    }

    if (error.response && error.response.status === 404) {
      router.push({ name: "NotFound" });
    }

    return Promise.reject(error);
  }
);

export default api;
