export const GET_ALL_ASSIGNED_JOBS = "GET_ALL_ASSIGNED_JOBS";
export const GET_ALL_REJECTED_JOBS = "GET_ALL_REJECTED_JOBS";
export const GET_ALL_FINISHED_JOBS = "GET_ALL_FINISHED_JOBS";
export const GET_ALL_APPROVED_JOBS = "GET_ALL_APPROVED_JOBS";
export const GET_JOB_BY_ID = "GET_JOB_BY_ID";
export const EDIT_JOB = "EDIT_JOB";
export const GET_CONTRACTORS_LIST = "GET_CONTRACTORS_LIST";
export const SET_CONTRACTORS = "SET_CONTRACTORS";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const DELETE_FILE = "DELETE_FILE";
export const ADD_NOTE_TO_JOB = "ADD_NOTE_TO_JOB";
export const GET_ORDER_FILES = "GET_ORDER_FILES";
export const CHANGE_CONTRACTOR_EMAIL = "CHANGE_CONTRACTOR_EMAIL";
