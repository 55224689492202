<template>
  <svg viewBox="0 0 16 19" :width="width" :height="height" class="lock-icon">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 5.125V7.75H2C0.931649 7.75 0 8.42933 0 9.35417V16.6458C0 17.5707 0.931649 18.25 2 18.25H14C15.0684 18.25 16 17.5707 16 16.6458V9.35417C16 8.42933 15.0684 7.75 14 7.75H13V5.125C13 2.70875 10.7614 0.75 8 0.75C5.23858 0.75 3 2.70875 3 5.125ZM11 5.125V7.75H5V5.125C5 3.67525 6.34315 2.5 8 2.5C9.65685 2.5 11 3.67525 11 5.125ZM2 16.5V9.5H14V16.5H2ZM9 13C9 13.4832 8.55229 13.875 8 13.875C7.44772 13.875 7 13.4832 7 13C7 12.5168 7.44772 12.125 8 12.125C8.55229 12.125 9 12.5168 9 13Z"
    />
  </svg>
</template>

<script>
export default {
  name: "LockIcon",
  props: {
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 19,
    },
  },
};
</script>
