import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { registerComponentsGlobal, loadPlugins } from "@/helpers";

// eslint-disable-next-line no-unused-vars
import scssStyles from "@/styles/app.scss";

export const app = createApp(App);

/**
 * Automatically load Vue plugins
 */
loadPlugins([
  "vue-toastification",
  "vcalendar",
  "i18n",
  "vue-select",
  "maska",
  "vue-clipboard",
]);

/**
 * Automatically import and register components globaly
 */
registerComponentsGlobal(app);

app.use(store).use(router).mount("#app");
