import {
  AUTH_SIGNUP,
  REGISTER_CONTRACTOR,
  AUTH_LOGIN,
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESEND_VERIFICATION_EMAIL,
  CHECK_EMAIL_EXISTS,
} from "../actions/auth";
import api from "@/api/api.js";
import router from "@/router/index";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("user-token") || "",
    status: "",
  },
  getters: {
    isAuth: (state) => {
      try {
        return !!state.token;
      } catch (e) {
        // throws InvalidTokenError exception
        state.status = "logout";
        state.token = "";
        localStorage.removeItem("user-token");
        return false;
      }
    },
  },
  mutations: {
    [AUTH_REQUEST](state, payload) {
      state.status = payload;
    },
    [AUTH_SUCCESS](state, payload) {
      state.status = "success";
      state.token = payload.token;
      api.defaults.headers["Authorization"] = `Bearer ${
        localStorage.getItem("user-token") || ""
      }`;
    },
    [AUTH_ERROR](state) {
      state.status = "error";
      state.token = "";
    },
    [AUTH_LOGOUT](state) {
      state.status = "logout";
      state.token = "";
      localStorage.removeItem("user-token");
      localStorage.removeItem("user-id");
      router.push({ name: "AuthPage" });
    },
    [FORGOT_PASSWORD](state) {
      state.status = "forgot password";
      state.token = "";
    },
    [RESET_PASSWORD](state) {
      state.status = "reset password";
      state.token = "";
    },
  },
  actions: {
    [AUTH_SIGNUP]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST, "loading");
        api
          .post("/register-client", payload)
          .then((response) => response.data.data)
          .then((data) => {
            commit(AUTH_REQUEST, "");
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [REGISTER_CONTRACTOR]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST, "loading");
        api
          .post("/register-contractor", payload)
          .then((response) => response.data.data)
          .then((data) => {
            commit(AUTH_REQUEST, "");
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [AUTH_LOGIN]({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST, "loading");
        api
          .post("/login", user)
          .then((response) => response.data.data)
          .then((data) => {
            commit(AUTH_REQUEST, "");
            localStorage.setItem("user-token", data.token);
            commit(AUTH_SUCCESS, data);
            resolve(data);
          })
          .catch((error) => {
            commit(AUTH_LOGOUT);
            reject(error);
          });
      });
    },
    [FORGOT_PASSWORD]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit(FORGOT_PASSWORD);
        api
          .post("/send-reset-password", payload)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            commit(AUTH_ERROR);
            reject(error);
          });
      });
    },
    [RESET_PASSWORD]({ commit }, formData) {
      return new Promise((resolve, reject) => {
        commit(RESET_PASSWORD);
        api
          .post(`/reset-password`, formData)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            commit(AUTH_ERROR);
            reject(error);
          });
      });
    },
    [RESEND_VERIFICATION_EMAIL]({ commit }, formData) {
      return new Promise((resolve, reject) => {
        api
          .post(`/send-mail-verification`, formData)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            commit(AUTH_ERROR);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-empty-pattern
    [CHECK_EMAIL_EXISTS]({}, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/check-if-email-exists`, params)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
