export const PHONEREGEXP =
  // /^((\\+[1-9]{1,3}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  /^\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?[0-9]{3,4}?[- .]?\d\d\d\d$/;
export const LETTER_REGEX = /^[aA-zZ\s]+$/;

export const ORDER_DOC_TYPES = [
  {
    NAME: "Academic Manuscript",
    TRANSLATION_KEY: "academic_manuscript",
  },
  {
    NAME: "Grant Proposal",
    TRANSLATION_KEY: "grant_proposal",
  },
  {
    NAME: "Admission Essay",
    TRANSLATION_KEY: "admission_essay",
  },
  {
    NAME: "Undergraduate Essay",
    TRANSLATION_KEY: "undergraduate_essay",
  },
  {
    NAME: "Business and Corporate",
    TRANSLATION_KEY: "business",
  },
];

export const ORDER_RATES = [
  {
    ID: 1,
    NAME: "Standard",
    TRANSLATION_KEY: "standart",
  },
  {
    ID: 2,
    NAME: "Premium",
    TRANSLATION_KEY: "premium",
  },
  {
    ID: 3,
    NAME: "Platinum",
    TRANSLATION_KEY: "platinum",
  },
];

export const ORDER_STATUSES = [
  {
    STATUS_NAME: "New Pending Payment",
    STATUS: "pending_payment",
    TRANSLATE_KEY: "pending_payment",
  },
  {
    STATUS_NAME: "In Progress Pending Editor",
    STATUS: "pending_editor",
    TRANSLATE_KEY: "pending_editor",
  },
  {
    STATUS_NAME: "In Progress Editing",
    STATUS: "in_progress",
    TRANSLATE_KEY: "in_progress",
  },
  {
    STATUS_NAME: "In Progress Pending Senior Editor",
    STATUS: "uneditable",
    TRANSLATE_KEY: "uneditable",
  },
  {
    STATUS_NAME: "Cancelled Pending Refund",
    STATUS: "pending_refund",
    TRANSLATE_KEY: "pending_refund",
  },
  {
    STATUS_NAME: "Cancelled Complete",
    STATUS: "cancelled",
    TRANSLATE_KEY: "cancelled",
  },
  {
    STATUS_NAME: "Fulfilled Pending Approval",
    STATUS: "pending_approval",
    TRANSLATE_KEY: "pending_approval",
  },
  {
    STATUS_NAME: "Re-edit Requested Pending Senior Editor",
    STATUS: "rejected",
    TRANSLATE_KEY: "rejected",
  },
  {
    STATUS_NAME: "Fulfilled Approved by Client",
    STATUS: "approved",
    TRANSLATE_KEY: "approved",
  },
  {
    STATUS_NAME: "Complete",
    STATUS: "completed",
    TRANSLATE_KEY: "completed",
  },
];

export const ROLES = {
  CLIENT: "client",
  CONTRACTOR: "contractor",
  ADMIN: "admin",
};

export const CRUD_MODE = {
  CREATE: "create",
  EDIT: "edit",
};

export const DOC_TYPES = {
  ORIGINAL: "original",
  EDITED: "edited",
};

export const NOTE_TYPES = {
  REJECT: "rejection",
  NOTE: "note",
  UNEDITABLE: "uneditable",
};

export const CLIENT_SUPPORT_EMAIL = "support@eneo.net.au";
export const CONTRACTOR_SUPPORT_EMAIL = "contractor.support@eneo.net.au";

export const EN_DOMAIN = "https://secure.eneo.net.au";

export const JA_DOMAIN = "https://secure.eneo.jp";

export const EN_PROMO_DOMAIN = "https://eneo.net.au";

export const JA_PROMO_DOMAIN = "https://eneo.jp";
