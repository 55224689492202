export const GET_USER_INFO = "GET_USER_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const GET_CONTRACTORS = "GET_CONTRACTORS";
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const DELETE_USER = "DELETE_USER";
export const BLOCK_USER = "BLOCK_USER";
