import store from "@/store/index.js";
import { ROLES } from "@/constants/commonConstants";

export const ifAuthenticated = (_to, _from, next) => {
  if (store.getters["auth/isAuth"]) {
    next();
    return;
  }

  next("/auth");
};

export const ifNotAuthenticated = (_to, _from, next) => {
  if (!store.getters["auth/isAuth"]) {
    next();
    return;
  }

  next("/");
};

export const validateUserManagmentRoute = (to, _from, next) => {
  function isValid(param) {
    return param === ROLES.CLIENT || param === ROLES.CONTRACTOR;
  }

  if (!isValid(to?.params?.userRole)) {
    next({ name: "NotFound" });
  }

  next();
};
