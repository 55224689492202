<template>
  <div>
    <p class="mb-20 mt-50 f-13-black f-bold">{{ $t("files.title") }}</p>
    <div class="unassigned-jobs-table">
      <div class="unassigned-jobs-table__header row-start f-12-white f-bold">
        <div class="lg-2 p-4 s-align-5">
          {{ $t("files.table.date_added") }}
        </div>
        <div class="lg-2 p-4 s-align-5">
          {{ $t("files.table.date_deleted") }}
        </div>
        <div class="lg-4 p-4 s-align-5">
          {{ $t("files.table.filenane") }}
        </div>
        <div class="lg-4 p-4 s-align-5">
          {{ $t("files.table.log") }}
        </div>
      </div>
      <div
        :class="[
          'unassigned-jobs-table__body row-start f-12-black',
          item.deleted_at ? 'bg-grey-2' : '',
        ]"
        v-for="item in files"
        :key="item.id"
      >
        <div class="lg-2 p-4 s-align-5 bb-black bl-black br-black">
          {{ dateFormat(item.created_at) }}
        </div>
        <div class="lg-2 p-4 s-align-5 bb-black bl-black br-black">
          <template v-if="!item.deleted_at">
            {{ $t("files.no_deleted") }}
          </template>
          <template v-else>{{ dateFormat(item.deleted_at) }}</template>
        </div>
        <div class="lg-4 p-4 s-align-5 bb-black br-black">
          <span class="underline pointer" @click="handleDownloadFile(item)">
            {{ item.original_name }}
          </span>
        </div>
        <div class="lg-4 p-4 bb-black br-black break-all">
          {{ getLogSignature(item) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, downloadFile } from "@/helpers/commonHelpers";

export default {
  name: "FilesTable",
  props: {
    files: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    // Methods imported from commonHelpers.js
    dateFormat,
    downloadFile,
    getLogSignature(item) {
      let logSignature = "Upload by:";
      let firstName = "";
      let lastName = "";
      let role = "";

      firstName = item?.owner?.first_name || "";
      lastName = item?.owner?.last_name || "";
      role = item?.owner?.role || "";

      logSignature += ` [${
        role.charAt(0).toUpperCase() + role.slice(1)
      }, ${firstName} ${lastName}]`;

      return logSignature;
    },
    handleDownloadFile({ id, original_name }) {
      this.downloadFile(id, original_name);
    },
  },
};
</script>
