<template>
  <div class="app-footer">
    <div class="app-footer__top">
      <div class="app-footer__container">
        <div class="row-between row-baseline">
          <div class="lg-9">
            <img
              src="@/assets/images/le-logo.webp"
              class="mr-20 company-logo"
            />
            <img src="@/assets/images/stripe.webp" class="mr-20 company-logo" />
            <p class="s-align-5 t-center f-13-black" style="width: 150px">
              {{ $t("footer.gua_security") }}
            </p>
          </div>
          <div class="column-center lg-3 f-13-black">
            <p>{{ $t("footer.contact_us") }} +61-7-3159-4643</p>
            <p>{{ $t("footer.contact_schedule") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="app-footer__bottom row-start">
      <div class="app-footer__container s-align-4">
        <div class="row-start lg-12">
          <img
            src="@/assets/images/logo.png"
            class="app-footer__logo"
            alt="Eneo Logo"
          />
          <div class="s-align-5 ml-8">
            <span class="f-11-white footer-copyright">
              {{ $t("footer.copyright") }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
